import {useAppSelector} from "../../../../../app/hooks";
import {selectCart} from "../../../cartSlice";
import {useEffect, useState} from "react";
import {CartType} from "../../../cartInterfaces";
import {Cart} from "../../../Cart";

export function CartMinOrderCharge() {
    const cart = useAppSelector(selectCart)
    const [fee, setFee] = useState(0);


    useEffect(() => {
        let cartM = new Cart(cart);
        setFee(cartM.calcDeliveryMinOrderFee());
    }, [cart])

    return (
        <>
            {cart.type === CartType.Delivery && fee > 0 &&
                <div className="flex place-content-between items-center content-center mb-2">
                    <div>
                        <h3 className="inline-block pr-2" color={"var(--theme-modded-fore-color)"}>Minimum Order Fee </h3>
                    </div>
                    <h2>&euro;{fee.toFixed(2).toString()}</h2>
                </div>
            }
        </>

    )
}
