import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectOpenSidebar, setOpenSidebar, SideBarOptions} from "../../../core/panelsSlice";
import {SideBar} from "../../../core/components/SideBar/SideBar";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {IoIosClose} from "react-icons/io";
import {VendorI} from "../../../vendor/vendorInterfaces";
import {selectVendor} from "../../../vendor/vendorSlice";
import {MenuCategoryI, MenuSubcategoryI} from "../../menuInterfaces";
import {selectMenu} from "../../menuSlice";
import * as styled from './MenuCatecoriesSideBar.styled'
import {BsChevronRight} from "react-icons/bs";
import {v4 as uuid} from "uuid";
import * as Scroll from "react-scroll";
import {generateSubcategoryId} from "../../menuService";

export function MenuCategoriesSideBar() {
    const openSidebar = useAppSelector(selectOpenSidebar);
    const dispatch = useAppDispatch();
    const vendor: VendorI = useAppSelector(selectVendor);
    const menu: Array<MenuCategoryI> = useAppSelector(selectMenu);
    const scroller = Scroll.scroller;

    const close = () => {
        dispatch(setOpenSidebar(SideBarOptions.CLOSED, ));
    }

    const showCategory =(category: MenuCategoryI) => {
        scroller.scrollTo(('category-'+category.Id), {
            smooth: false,
            offset: -210,
            ignoreCancelEvents: true,
        });
        close();
    }
    const showSubcategory = (category: MenuCategoryI, subcategory: MenuSubcategoryI) => {
        const identifier = generateSubcategoryId(category.Id, subcategory);
        scroller.scrollTo((identifier), {
            smooth: false,
            offset: -210,
            ignoreCancelEvents: true,
        });
        close();
    }


    return (
        <SideBar open={openSidebar === SideBarOptions.CATEGORIES}>
            <div className="flex flex-col h-full overflow-y-scroll overscroll-contain">
                <div className="py-4" style={{position: 'relative'}}>
                    <div className="indent flex items-center">
                        <ActionButton
                            onClick={close}
                            style={{position: 'absolute', left: '10px'}}
                        >
                            <IoIosClose size="32"/>
                        </ActionButton>
                        <div className={'px-8 text-center flex-1 ' + (vendor.Name.length > 20 ? 'pl-16' : '')}>
                            <p color="var(--theme-modded-fore-color)">{vendor.Name}</p>
                        </div>
                    </div>
                    <div className="py-4 mt-4">
                        {menu.map((category) => {
                            return <div key={uuid()}>
                            {category.Subcategories.length > 0 &&
                                <styled.Category onClick={() => showCategory(category)}>
                                    {category.Name}
                                    <span><BsChevronRight/></span>
                                </styled.Category>
                            }
                                {category.Subcategories.map(subcategory => {
                                    if(subcategory.Name !== 'None') {
                                        return <styled.Subcategory
                                            key={uuid()}
                                            onClick={() => {showSubcategory(category, subcategory)}}
                                        >
                                            {subcategory.Name}
                                        </styled.Subcategory>
                                    }return null;
                                })}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </SideBar>
    )
}
