import styled from "styled-components";

const QuantitySwitchContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

interface QuantitySwitchButtonI {
    outline?: boolean,
    disabled?: boolean
}
const QuantitySwitchButton = styled.div<QuantitySwitchButtonI>`
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid thin var(--theme-primary-color);
  background-color: ${props => props.outline ? 'white' : 'var(--theme-primary-color)' };
  ${props => props.disabled && `background-color: grey`};
  color: ${props => props.outline ? 'var(--theme-primary-color)' : 'white' };
  cursor: pointer;
`;

const QuantitySwitchNumber = styled.div`
  width: 40px;
  text-align: center;
  color: var(--theme-modded-fore-color);
`;

export {
    QuantitySwitchButton,
    QuantitySwitchNumber,
    QuantitySwitchContainer
}
