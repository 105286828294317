export const translations: { [x: string]: { [x: string]: any } } = {
    En: {
        order_failed: 'Order failed',
        order_again: 'Order Again',
        search_field_placeholder: 'Search Menu',
        search_description: 'Type (3) characters or more to start searching.',
        search_description_no_results: 'No results found',
        table: 'Table',
        product_notes_comment: 'Notes for the kitchen',
        cart: 'Cart',
        order_items: 'Order Items',
        order: 'Order',
        order_status_outlet_title: "OUTLET'S NAME",
        order_status_date_title: "ORDER DATE & TIME",
        order_status_orderId_title: "ORDER ID",
        order_status_table_title: "TABLE",
        order_status_items_title: "ITEMS",
        order_status_comments_title: "Order Comments",
        order_status_total_title: "Total Cost",
        order_status_payment_title: "Payment Method",
        order_status_address: "DELIVERY ADDRESS",
        cart_screen_description: '',
        cart_screen_comments: 'Comments',
        cart_is_empty: 'Cart is empty',
        checkout: 'Checkout',
        update: 'Update',
        remove: 'Remove',
        resend: 'Resend',
        notes: 'Notes',
        next: 'Next',
        total: 'Total',
        add_to_cart: 'Add to cart',
        view_order: 'View Order',
        call: 'Call',
        my_orders: 'My orders',
        login: 'Login',
        logout: 'Logout',
        sign_in_title: 'Welcome',
        sign_in_description: 'Enter your phone number so that you can proceed further.',
        mobile_number: 'Mobile Number',
        first_name: 'Name',
        last_name: 'Last Name',
        email_address_from_field: 'Email address (optional)',
        register_title: 'Register',
        register_description: 'Please add your details so that we can send you an OTP code to login.',
        get_otp_button: 'Get OTP',
        otp_screen_title: 'Enter the OTP that was sent to',
        otp_field_label: 'OTP code',
        otp_field_description: "Didn't get a code?",
        checkout_screen_description: "Please choose your payment method",
        order_again_box_title: "Order Again",
        order_again_box_description: "Craving to repeat your last order?",
        order_again_box_button: "Order Again",
        service_hours: "Service Hours",
        no_internet_msg: "No Internet Connection.",
        room_payment_sheet_title: "Room Number",
        room_payment_sheet_button: "Submit Order",
        room_payment_sheet_error: "Please enter your room number.",
        account_payment_sheet_title: "Account Charge",
        account_payment_sheet_error: "No Accounts Found!",
        account_payment_sheet_description: "Choose an account:",
        button_back_to_menu: "Back to menu",
        take_away: "Take Away",
        delivery: "Delivery",
        order_type_screen_title: "Order online in just 2 minutes!",
        previous_step: "PREVIOUS STEP",
        post_code: 'Post code',
        city: 'City',
        street_name: 'Street Name',
        street_number: 'Street Number',
        building_name: 'Building Name',
        floor: 'Floor',
        flat_number: 'Flat No',
        address_notes: 'Address Notes',
        save: 'Save',
        add_address_step_2_title: "Add post code",
        add_address_step_2_desc_1: "Enter your post code so that we can see",
        add_address_step_2_desc_2: "if we can deliver to you!",
        add_address_step_3_title: "Add new address",
        require_option: "Please select one option",
        name_validation : 'Please enter your full name',
        email_validation: 'Please enter a valid email.',
    },
    Gr: {
        order_failed: 'Η παραγγελία απέτυχε',
        order_again: 'Παραγγείλετε ξανά',
        search_field_placeholder: 'Αναζήτηση',
        name_validation : 'Παρακαλώ εισάγετε το πλήρες όνομά σας',
        search_description: 'Πληκ/στε 3 χαρακτήρες για αναζήτηση',
        email_validation: 'Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email',
        search_description_no_results: 'Δεν βρέθηκε',
        table: 'Τραπέζι',
        product_notes_comment: 'Σχόλια',
        cart: 'Καλάθι',
        order_items: 'Προϊόντα',
        order: 'Παραγγελία',
        order_status_outlet_title: "Κατάστημα",
        order_status_date_title: "Ημερομηνία και Ώρα Παραγγελίας",
        order_status_orderId_title: "Κωδικός Παραγγελίας",
        order_status_table_title: "Τραπέζι",
        order_status_items_title: "Προϊόντα",
        order_status_comments_title: "Σχόλια Παραγγελίας",
        order_status_total_title: "Ολικό",
        order_status_payment_title: "Μέθοδος Πληρωμής",
        order_status_address: "Διεύθυνση Παράδοσης",
        cart_screen_description: '',
        cart_screen_comments: 'Σχόλια',
        cart_is_empty: 'Το καλάθι είναι άδειο! ',
        checkout: 'Ολοκλήρωση',
        update: 'Ανανέωση',
        remove: 'διαγραφή',
        resend: 'Ξαναδοκίμασε',
        notes: 'Σημειώσεις',
        next: 'Επόμενο',
        total: 'Ολικό',
        add_to_cart: 'Προσθήκη',
        view_order: 'Προβολή Παραγγελίας',
        call: 'Κλήση',
        my_orders: 'Οι Παραγγελίες μου',
        login: 'Σύνδεση',
        logout: 'Αποσύνδεση',
        sign_in_title: 'Καλωσήρθατε',
        sign_in_description: 'Καταχορήστε αριθμό κινητού για να προχωρήσετε.',
        mobile_number: 'Αριθμός κινητού',
        first_name: 'Ονομα',
        last_name: 'Επώνυμο',
        email_address_from_field: 'Ηλεκτρονική Διεύθυνση (Προαιρετικό)',
        register_title: 'Εγγραφή',
        register_description: 'Παρακαλώ καταχωρήστε τις πληροφορίες για επικύρωση στοιχείων σύνδεσης.',
        get_otp_button: 'Λήψη OTP',
        otp_screen_title: 'Καταχώρηση κωδικού OTP',
        otp_field_label: 'Κωδικός OTP',
        otp_field_description: "Δεν λάβατε κωδικό?",
        checkout_screen_description: "Επιλέξτε μέθοδο πληρωμής",
        order_again_box_title: "Νέα Παραγγελία",
        order_again_box_description: "Θέλετε να επαναλάβετε την τελευταία σας παραγγελία;",
        order_again_box_button: "Παραγγείλετε ξανά",
        service_hours: "Ώρες Εξυπηρέτησης",
        no_internet_msg: "Δεν υπάρχει σύνδεση στο δίκτυο.",
        room_payment_sheet_title: "Αριθμός Δωματίου",
        room_payment_sheet_button: "Αποστολή Παραγγελίας",
        room_payment_sheet_error: "Καταχωρήστε αριθμό δωματίου.",
        account_payment_sheet_title: "Χρέωση Λογαριασμού",
        account_payment_sheet_error: "Ο λογαριασμός δεν υπάρχει!",
        account_payment_sheet_description: "Επιλέξτε τον λογαριασμό σας:",
        button_back_to_menu: "Επιστροφή στο μενού",
        take_away: "Take Away",
        delivery: "Delivery",
        order_type_screen_title: "Παραγγείλετε με 3 κλικς!",
        previous_step: "Επιστροφή",
        post_code: 'Ταχυδρομικός κώδικας',
        city: 'Πόλη',
        street_name: 'Διεύθυνση',
        street_number: 'Αριθμός',
        building_name: 'Ονομα Κτιρίου',
        floor: 'Όροφος',
        flat_number: 'Αριθμός Διαμερίσματος',
        address_notes: 'Σημειώσειε',
        save: 'Αποθήκευση',
        add_address_step_2_title: "Προσθέστε ταχυδρομικό κώδικα",
        add_address_step_2_desc_1: "Προσθέστε ταχυδρομικό κώδικα",
        add_address_step_2_desc_2: "για παράδοση στο σπίτι!",
        add_address_step_3_title: "Προσθήκη νέας διέθυνσης",
       
        require_option: "Παρακαλώ Επιλέξτε",
    
    },
    Du: {search_field_placeholder: 'Suche'},
    Ru: {search_field_placeholder: 'поиск'}
};
