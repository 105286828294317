import * as styled from './UserAddresses.styled';
import {AiOutlinePlus} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

export function AddAddressBox() {
    const navigate = useNavigate();

    const add = () => {
        navigate('/address/add');
    }
    return (
        <styled.AddAddressBox onClick={add}>
            <div className={'wrapper'}>
                <div className="plus-wrapper">
                    <AiOutlinePlus size={40} />
                </div>
                Add New Address
            </div>
        </styled.AddAddressBox>
    )
}
