import * as styled from './MenuHeader.styled';
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectVendor} from "../../../vendor/vendorSlice";
import CONFIG from "../../../../app/config";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsInfo} from "react-icons/bs";
import {setOpenSidebar, SideBarOptions} from "../../../core/panelsSlice";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";

export function MenuHeader() {
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
    return (
        <styled.Header background={vendor.CoverImage ? (CONFIG.COVERS_URL + vendor.CoverImage) : undefined}>
            <VendorLogo placement={VendorLogoPosition.Header}  />
            {/*<styled.Logo src={CONFIG.LOGOS_URL + vendor.FileName} />*/}
            <styled.Actions left>
                <ActionButton>
                    <BsInfo size="32" onClick={() => dispatch(setOpenSidebar(SideBarOptions.SETTINGS))}/>
                </ActionButton>
            </styled.Actions>
        </styled.Header>
    )
}
