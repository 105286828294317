import {CartI} from "./cartInterfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

export interface PastOrderI {
   
    VendorId: string,
    OrderId: string,
    PaymentRequestID?: string,
    Cart: CartI
}

export interface PastOrdersI{
    orders: Array<PastOrderI>
}

const initialState: PastOrdersI = {
    orders: []
}
export const ordersSlice = createSlice({
    name: 'ordersSlice',
    initialState,
    reducers: {
        addOrderToPastOrders: (state, action: PayloadAction<PastOrderI>) => {
            let orders = [...state.orders];
            const i = orders.findIndex(order => {
                return order.VendorId === action.payload.VendorId
            })
            if(i >= 0){
                orders.splice(i, 1);
            }
            state.orders = [...orders, action.payload]
        },
        clearPastOrders: (state) => {
            state.orders = [];
        }
    }
})

export default ordersSlice.reducer;
export const {addOrderToPastOrders, clearPastOrders} = ordersSlice.actions;
export const selectLastOrder = (state: RootState) => {
    const vendorId = state.vendor.data.OutletID;
    const i = state.purchases.orders.findIndex(order => {
        return order.VendorId === vendorId
    })
    if(i >= 0){
        return state.purchases.orders[i]
    }
    return null;
}
export const selectLastOrderCart = (state: RootState) => {
    const vendorId = state.vendor.data.OutletID;
    const i = state.purchases.orders.findIndex(order => {
        return order.VendorId === vendorId
    })
    if(i >= 0){
        return state.purchases.orders[i].Cart
    }
    return null;
}
