import CONFIG from "../../app/config";
import {TableInfoI, VendorI, VendorOperation} from "../vendor/vendorInterfaces";
import {isProductCategoryAvailableInScheduleI, MenuItemI, MenuSubcategoryI} from "./menuInterfaces";
import {UserI} from "../user/userInterfaces";

export function canShowAddToCart({vendorOperationId, table}: {
    vendorOperationId?: String,
    table?: TableInfoI
}) {
    return (
        table?.TypeName !== "Menu" &&
        vendorOperationId === VendorOperation.DigitalOrdering &&
        CONFIG.ENABLE_CART
    )
}

export function canShowVariationPrices({price, vendorVisibility}: {
    price: String,
    vendorVisibility?: String
}) {
    return (
        price && price !== "0"
        // vendorVisibility === VendorVisibility.MenuWithPrices

    )
}

export function canShowProductKitchenNotes({vendor}: {
    vendor: VendorI,
}) {
    return vendor.OperationID === VendorOperation.DigitalOrdering

}

export function canShowMenuItemPrice({price}: {
    price?: String
}) {
    return (
        price &&
        price !== "0" &&
        price !== "-"
    )
}

export function canShowCartButton({cartItemsLength, table}:{
    cartItemsLength: number,
    table?: TableInfoI
}) {
    return (
        table?.TypeName !== "Menu" &&
        cartItemsLength > 0 &&
        CONFIG.ENABLE_CART
    )
}

export function canUserOrder({user, vendor}: {
    user: UserI,
    vendor: VendorI,
}) {
    return (
        canGuestUserOrder({vendor}) ||
            user.RegisteredUserID !== CONFIG.GUEST_USER_ID
    )
}

export function canGuestUserOrder({vendor}: {
    vendor: VendorI,
}) {
    return (
        CONFIG.ENABLE_GUEST_CHECKOUT &&
        vendor.AllowGuestUsers === "1"
    )
}

export function canOrderAgain({vendor}: {
    vendor: VendorI,
}) {
    return vendor.OperationID === VendorOperation.DigitalOrdering
}

export function isProductCategoryAvailableInSchedule({product} : {
    product: MenuItemI
}): isProductCategoryAvailableInScheduleI {
    if(product.menucategoryDetails.length > 0){
        let res = product.menucategoryDetails.find(mc => mc.CurrentlyAvailable === "1");
        if (res){
            return {
                isAvailable: true
            };
        }else{
            return {
                isAvailable: false,
                availabilityDetailsMsg: 'Served Between: ' + product.StartTime?.slice(0, -3) + ' - ' + product.EndTime?.slice(0, -3)
            };
        }
    }
    return {
        isAvailable: true
    };
}

export function canShowTableInfo({table}:{
    table?: TableInfoI
}){
    return (
        table &&
        table?.TypeName !== "Menu"
    )
}

export function generateSubcategoryId(
    categoryId: string,
    subcategory: MenuSubcategoryI,
    prepend?: string
): string
{
    let result = prepend ?? "";
    result += categoryId + ('-subcategory-' + subcategory.Id);
    return result;
}
