import styled, {css} from "styled-components";
interface MenuOrderAgainContainerI {
    visible?: boolean
}
export const MenuOrderAgainContainer = styled.div<MenuOrderAgainContainerI>`
  padding-left: var(--theme-walls-indentation);
  padding-right: var(--theme-walls-indentation);
  margin-bottom: 20px;
  display: ${props => props.visible ? 'block' : 'none'};
  .order-again-box{
    display: flex;
    padding: 8px;
    background-color: var(--theme-modded-card-bg);
    min-height: 100px;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: var(--theme-shadow);
    .order-again-box-btn{
      background: var(--theme-primary-color);
      color: white;
      padding: 10px;
      border-radius: 5px;
      margin: 5px;
    }
  }
`
