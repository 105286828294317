import {SideBar} from "../../../core/components/SideBar/SideBar";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectOpenSidebar, setOpenSidebar, SideBarOptions} from "../../../core/panelsSlice";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {IoIosClose} from "react-icons/io";
import {DarkModeSwitch} from "./DarkModeSwitch";
import {LocalePicker} from "./LocalePicker/LocalePicker";
import {AppCredits} from "../../../core/components/AppCredits/AppCredits";
import {logoutUser, selectUser} from "../../userSlice";
import {setFavourites} from "../../../menu/menuSlice";
import {setVendorFavourites} from "../../../vendor/vendorSlice";
import {clearPastOrders} from "../../../cart/ordersSlice";
import {UserStatus} from "../../userInterfaces";
import {Translate} from "../../../intl/translator";

export function SettingsSidebar() {
    const strings = {
        s_login: Translate('login'),
        s_logout: Translate('logout'),
    }
    const openSidebar = useAppSelector(selectOpenSidebar);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    const close = () => {
        dispatch(setOpenSidebar(SideBarOptions.CLOSED));
    }

    const logout = () => {
        dispatch(setOpenSidebar(SideBarOptions.CLOSED))
        dispatch(logoutUser());
        dispatch(setFavourites([]));
        dispatch(clearPastOrders());
        dispatch(setVendorFavourites([]));
    }

    return (
        <SideBar open={openSidebar === SideBarOptions.SETTINGS}>
            <div className="flex flex-col h-full">
                <div>
                    <div className="flex py-5 px-5 place-content-between items-center">
                        <div className="flex">
                            <div className="pr-3">
                                <ActionButton onClick={close}>
                                    <IoIosClose size="32"/>
                                </ActionButton>
                            </div>
                            <DarkModeSwitch />
                        </div>
                        {user.Verified === UserStatus.Verified &&
                            <div className="text-xl font-medium cursor-pointer" onClick={logout}>
                                {strings.s_logout}
                            </div>
                        }
                    </div>
                    <LocalePicker />
                </div>
                <div className="flex-grow"><hr/></div>
                <AppCredits />
            </div>
        </SideBar>
    )
}
