import {MenuHeader} from "../MenuHeader/MenuHeader";
import './menu-screen.scss';
import {MenuResults} from "../MenuResults/MenuResults";
import {CategoriesBar} from "../MenuCategoriesBar/CategoriesBar/CategoriesBar";
import {MenuCategoriesSideBar} from "../MenuCategoriesSidebar/MenuCategoriesSideBar";
import {Product} from "../Product/Product";
import {VendorDetails} from "../../../vendor/components/VendorDetails";
import {CartSidebar} from "../../../cart/components/CartScreen/CartSidebar";
import {ViewOrderButton} from "./ViewOrderButton/ViewOrderButton";
import {CartDrawer} from "../../../cart/components/CartScreen/CartDrawer";

import { useAppSelector} from "../../../../app/hooks";
import {isEqual} from "lodash";
import {selectLastOrderCart} from "../../../cart/ordersSlice";
import {MenuOrderAgain} from "./MenuOrderAgain";
export function MenuScreen() {

    const lastCart = useAppSelector(selectLastOrderCart,isEqual);


    return (
        <div id="menu-screen">
            <MenuHeader />
            <CategoriesBar />
            <MenuCategoriesSideBar />
            <Product />
            <CartDrawer />
           

            <div id="menu-content-wrapper" className={'menu-content-wrapper'}>
                <div className={'menu-content'}>
                {lastCart &&
                               
                               <MenuOrderAgain lastCart={lastCart}/>
                           }
                    <MenuResults />
                </div>
                <div className={'menu-sidebar'}>
                    <VendorDetails/>
                    <CartSidebar />
                </div>
            </div>
            <ViewOrderButton />
        </div>
    )
}
