import {AppCreditsContainer} from "./AppCredits.styled";

export function AppCredits() {
    return (
        <AppCreditsContainer className="indent">
            <hr/>
            <a target="_blank" href="https://ninacy.com" className="text-xl fore-color" rel="noreferrer">
                Powered by <span>nina</span>
            </a>
           {/* <a target="_blank" href="https://ideaseven.com" className="text-xl fore-color" rel="noreferrer">
                Developed by <span>Ideaseven</span>
            </a>*/}
        </AppCreditsContainer>
    )
}
