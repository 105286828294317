import styled from "styled-components";
import {MediaQueries} from "../styles/queries";

interface SizedContentPageI {
    height?: string
}

const SizedContentPage = styled.section<SizedContentPageI>`
  height: 68vh;
  background: white;
  .actions{
    
  }
  @media screen and ${MediaQueries.portrait} {
    height: 100px;
  }
  
`;

export default SizedContentPage;
