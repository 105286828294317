import * as styled from './VendorLogo.styled';
import {useAppSelector} from "../../../../app/hooks";
import {selectVendor} from "../../vendorSlice";
import CONFIG from "../../../../app/config";
import {VendorLogoPosition} from "./VendorLogo.styled";

type Props  = {
    placement: VendorLogoPosition
}
export function VendorLogo({placement}: Props) {
    const vendor = useAppSelector(selectVendor);
    return (
        <styled.VendorLogo src={CONFIG.LOGOS_URL + vendor.FileName} placement={placement}/>
    )
}
