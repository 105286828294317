import styled, {css} from "styled-components";

interface LocaleButtonProps {
    isActive?: boolean
}

const LocaleButton = styled.img<LocaleButtonProps>`
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  ${props => props.isActive && css`
    border: solid 2px var(--theme-primary-color);
  `};
`;

export {LocaleButton}
