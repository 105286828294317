import styled from "styled-components";

interface ActionButtonProps {
    background?: string;
    color?: string;
    labeled?: boolean;
}

const ActionButton = styled.div<ActionButtonProps>`
  width: 38px;
  height: 38px;
  background-color: ${props => props.background ? props.background : `white`};
  border-radius: 10px;
  margin: 1px 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px #d3d1d860;
  ${props => props.labeled &&
    `width: unset; padding: 0 15px`
}
`;

interface CustomSwitchContainerProps {
    switchOnColor: string;
    switchOffColor: string;
    status: boolean;
}
const CustomSwitchContainer = styled.div<CustomSwitchContainerProps>`
  display: inline-flex;
  height: 38px;
  width: 150px;
  overflow: hidden;
  background-color: ${props => props.status ? props.switchOnColor : props.switchOffColor};
  border-radius: 6px;
  align-items: center;
  padding: 0 10px;
  transition: background-color 750ms;
  box-shadow: 0 0 5px #d3d1d830;
  & div:first-child {
    color: white;
    min-width: 100px;
    transition: margin-left 500ms;
    font-size: 18px;
    margin-left: ${props => props.status ? '0' : '-100px'};
  }
  & div:last-child {
    color: white;
    min-width: 100px;
    padding-left: 10px;
    font-size: 18px;
  }
`;

const CustomSwitchHandle = styled.div`
  width: 30px;
  height: 30px;
  aspect-ratio: 1;
  border-radius: 15px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    color: #333;
  }
`;

interface RoundButtonSmallI {
    diameter?: string
}

const RoundButtonSmall = styled.div<RoundButtonSmallI>`
  width: ${props => props.diameter ? props.diameter : '45px'};
  aspect-ratio: 1;
  float: right;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
`;
export {
    ActionButton,
    CustomSwitchContainer,
    CustomSwitchHandle,
    RoundButtonSmall
}
