import {Cart} from "../../../Cart";
import {CartDeliveryCharge} from "./CartDeliveryCharge";
import {CartMinOrderCharge} from "./CartMinOrderCharge";

interface Props {
    cart: Cart
}

export function CartCosts({cart}:Props) {

    return (
        <div className="indent pb-8">
            <hr className="py-3"/>
            <CartMinOrderCharge />
            <CartDeliveryCharge />
            <div className="flex place-content-between items-center content-center">
                <div>
                    <h3 className="inline-block pr-2" color={"var(--theme-modded-fore-color)"}>Total</h3>
                    <p className="inline-block">
                        ({cart.itemsCount()} item{cart.itemsCount() > 1 && "s"})
                    </p>
                </div>
                <h2>&euro;{cart.displayFormattedPrice()}</h2>
            </div>
        </div>
    )
}
