import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import { lockBodyScroll, unlockBodyScroll } from '../menu/components/MenuResults/MenuItem/scrollLock';
export enum SideBarOptions {
    CLOSED,
    CATEGORIES,
    SETTINGS,
    CART
}

export enum LightboxOptions {
    CLOSED,
    PRODUCT,
    CONFIRM_MIN_CHARGE
}

interface PanelsState {
    openSideBar: SideBarOptions,
    openLightbox: LightboxOptions
}

const initialState: PanelsState ={
    openSideBar: SideBarOptions.CLOSED,
    openLightbox: LightboxOptions.CLOSED
}

export const panelsSlice = createSlice({
    name: 'panelsSlice',
    initialState,
    reducers:{
        setOpenSidebar: (state, action: PayloadAction<SideBarOptions>) => {
            state.openSideBar = action.payload;
            let element = document.getElementById("menu-content-wrapper");
            if(action.payload !== SideBarOptions.CLOSED){
                element?.classList.add("overlay-open");
            }else{
                element?.classList.remove("overlay-open");
            }
        },
        setOpenLightbox: (state, action: PayloadAction<LightboxOptions>) => {
            state.openLightbox = action.payload;
            let element = document.getElementById("menu-content-wrapper");
            if (action.payload !== LightboxOptions.CLOSED) {
                lockBodyScroll(); // Lock the scroll when the lightbox is open
                element?.classList.add("overlay-open");
            } else {
                unlockBodyScroll(); // Unlock the scroll when the lightbox is closed
                element?.classList.remove("overlay-open");
            }
        },
    }
})

export default panelsSlice.reducer;
export const {
    setOpenSidebar,
    setOpenLightbox
} = panelsSlice.actions;
export const selectOpenSidebar = (state: RootState) => state.panels.openSideBar;
export const selectOpenLightbox = (state: RootState) => state.panels.openLightbox;
