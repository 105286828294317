import {MenuItemAllergenI} from "../../menuInterfaces";
import CONFIG from "../../../../app/config";
import {useState} from "react";
import {BottomSheet} from "react-spring-bottom-sheet";
import {v4 as uuid} from "uuid";

interface ProductAllergensInterface {
    items: Array<MenuItemAllergenI>
}

export function ProductAllergens({items} : ProductAllergensInterface) {
    const [show, setShow] = useState(false);
    return (
        <>
            {items.length > 0 &&
                <div className="mt-4 flex" >
                    {items.map(item => {
                        return <img key={uuid()} onClick={() => setShow(true)} className='mr-2 h-10' src={CONFIG.ALLERGEN_IMAGES_URL +  item.Icon} alt="allergen"/>
                    })}
                </div>
            }
            <BottomSheet open={show} onDismiss={() => setShow(false)}>
                <div className='text-center py-20'>
                    <h3>Allergens</h3>
                    <p className="mt-3">
                        {items.map((item, i, {length}) => {
                            return `${item.Name}` + (i + 1 === length ? '' : ', ')
                        })}
                    </p>
                </div>
            </BottomSheet>

        </>
    );
}
