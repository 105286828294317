import React from "react";
import {MenuCategory} from "./MenuCategory/MenuCategory";
import {v4 as uuid} from "uuid";
import {useAppSelector} from "../../../../app/hooks";
import {selectMenu} from "../../menuSlice";

export function MenuResults() {

    const menuItems = useAppSelector(selectMenu);

    return(
        <div>
            {menuItems.map(category => {
                if(Object.keys(category.Subcategories).length && !category.filtered){
                    return <MenuCategory
                        key={category.Name}
                        Name={category.Name}
                        Id={category.Id}
                        Subcategories={category.Subcategories}
                        CurrentlyAvailable={category.CurrentlyAvailable}
                    />
                }else{
                    return <div key={uuid()}></div>
                }
            })}
        </div>
    );
}
