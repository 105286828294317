import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {selectUser, selectUserAddress} from "../../../../user/userSlice";
import {useEffect} from "react";
import {selectVendor} from "../../../../vendor/vendorSlice";
import {getDeliveryCost} from "../../../cartRepository";
import {selectCart, selectCartType, setDeliveryCost} from "../../../cartSlice";
import {CartType} from "../../../cartInterfaces";

export function CartDeliveryCharge() {
    const address = useAppSelector(selectUserAddress);
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
    const cart = useAppSelector(selectCart)
    const user = useAppSelector(selectUser)
    const type = useAppSelector(selectCartType);

    useEffect(() => {
        if (address && type === CartType.Delivery) {
            getDeliveryCost(vendor.OutletID, address.LocationID)
                .then(res => {
                    dispatch(setDeliveryCost(res ?? 0))
                }).catch(e => {
                dispatch(setDeliveryCost(0))
            })
        } else {

        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    return (
        <>
            {cart.type === CartType.Delivery && user.Address &&
                <div className="flex place-content-between items-center content-center mb-2">
                    <div>
                        <h3 className="inline-block pr-2" color={"var(--theme-modded-fore-color)"}>Delivery </h3>
                    </div>
                    <h2>&euro;{(cart.deliveryCharge ?? 0).toFixed(2).toString()}</h2>
                </div>
            }
        </>

    )
}
