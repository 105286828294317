import React, { useEffect } from 'react';
import {MdStarRate} from "react-icons/md";
import {MenuItemI} from "../../../menuInterfaces";
import {v4 as uuid} from "uuid";
import Config from "../../../../../app/config";
import {useAppDispatch} from "../../../../../app/hooks";
import {setItemSelected, setProductView} from "../../../menuSlice";
import * as styled from './MenuItem.styled';
import {LightboxOptions, setOpenLightbox} from "../../../../core/panelsSlice";
import { lockBodyScroll, unlockBodyScroll } from './scrollLock';
interface MenuItemProps {
    inCart: number;
    popular?: boolean;
    item: MenuItemI
}

export function MenuItem(props: MenuItemProps) {

    const dispatch = useAppDispatch();

    const openItem = () => {
        lockBodyScroll();
        dispatch(setItemSelected(props.item));
        dispatch(setProductView({
            mode: "product",
            open: true
        }))
        dispatch(setOpenLightbox(LightboxOptions.PRODUCT));
    }

    useEffect(() => {
        return () => {
            unlockBodyScroll();
        };
    }, []);

    return (
        <styled.Container
            onClick={openItem}
            className={`${props.inCart > 0 ? "inOrder" : ""}`}
            key={uuid()}
        >
         <styled.Details>
             <styled.Title>
                 {props.item.ItemName}
                 {props.inCart > 0 &&
                     <styled.Quantity>{props.inCart}</styled.Quantity>
                 }
             </styled.Title>
             <styled.Description className={'line-clamp-3'}>
                 {/*{formatLength(props.item.Description, 60, "...")}*/}
                 {props.item.Description}
             </styled.Description>
             <div className="mt-2">
                 <styled.Price>&euro;{Number(props.item.Price).toFixed(2)}</styled.Price>
                 {props.item.IsPopular === "1" &&
                     <styled.Tag> <MdStarRate className="inline-block"/> Popular</styled.Tag>
                 }
             </div>
         </styled.Details>
            {props.item.FileName &&
                <styled.Image>
                    <img src={Config.MENU_IMAGES_URL + props.item.FileName} alt=""/>
                </styled.Image>
            }
        </styled.Container>
    )
}
