import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TableInfoI, VendorButtonI, VendorI, VendorOpenStatusI, VendorPaymentMethodI, VendorScheduleI} from "./vendorInterfaces";
import {RootState} from "../../app/store";
import {Vendor} from "./Vendor";

interface VendorState {
    data: VendorI,
    buttons?: Array<VendorButtonI>,
    table?: TableInfoI,
    schedule: VendorScheduleI,
    favourites: Array<VendorI>,
    paymentMethods: Array<VendorPaymentMethodI>
}

const initialStateStart: VendorState = {
    data: {
        Name: "None",
        OutletID: "0"
    },
    buttons: [],
    schedule: {
        ClosingSoon: "",
        Service_hours: "",
        working_hours: ""
    },
    favourites: [],
    paymentMethods: [],
}

export const vendorSlice = createSlice({
    name: 'vendorSlice',
    initialState: initialStateStart,
    reducers: {
        setVendor: (state, action: PayloadAction<VendorI>) => {
            state.data = action.payload;
        },
        setVendorButtons: (state, action: PayloadAction<Array<VendorButtonI>>) => {
            state.buttons = action.payload;
        },
        setTableInfo: (state, action: PayloadAction<TableInfoI>) => {
            state.table = action.payload;
        },
        setVendorSchedule:(state, action: PayloadAction<VendorScheduleI>) => {
            state.schedule = action.payload;
        },
        setVendorFavourites:(state, action: PayloadAction<Array<VendorI>>) => {
            state.favourites = action.payload;
        },
        setVendorPaymentMethods:(state, action: PayloadAction<Array<VendorPaymentMethodI>>) => {
            state.paymentMethods = action.payload;
        },
        setVendorOpenStatus: (state, action: PayloadAction<VendorOpenStatusI>) => {
            state.data.OpenStatus = action.payload;
        },
        unsetVendor: state => {
            state.data = {
                Name: "None",
                OutletID: "0"
            }
            state.buttons = [];
        }
    }
})

export const {
    setVendor,
    setVendorButtons,
    setVendorSchedule,
    setTableInfo,
    unsetVendor,
    setVendorFavourites,
    setVendorPaymentMethods,
    setVendorOpenStatus
} = vendorSlice.actions;

export default vendorSlice.reducer;
export const selectVendorData = (state: RootState) => state.vendor.data;
export const selectVendorButtons = (state: RootState) => state.vendor.buttons;
export const selectVendorSchedule = (state: RootState) => state.vendor.schedule;
export const selectVendorFavourites = (state: RootState) => state.vendor.favourites;
export const selectVendorPaymentMethods = (state: RootState) => {
    let orderingType = state.cart.type;
    return state.vendor.paymentMethods.filter(m => {
        return m.OrderType === orderingType?.toString()
    })
}
export const selectTable = (state: RootState) => state.vendor.table;
export const selectVendor = (state: RootState) => {
    return new Vendor(state.vendor.data);
}
