import styled from "styled-components";

export const AppCreditsContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  hr{
    width: 100%;
    margin-bottom: 25px;
  }
  p{
    color: var(--theme-modded-fore-color);
  }
  span{
    color: var(--theme-primary-color)
  }
`;
