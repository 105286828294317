import styled, {css} from "styled-components";

interface CategoryI {
    isActive?: boolean;
    isDisabled?: boolean;
}

const Category = styled.div<CategoryI>`
  border-radius: 5px;
  padding: 10px 15px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  white-space: nowrap;
  user-select: none;
  ${props => props.isActive && css`
    background-color: var(--theme-primary-color);
    color: white;
  `};
  ${props => props.isDisabled && css`
    //background-color: #f4f4f4;
    color: #abaaaa;
  `};
`;

export {Category}
