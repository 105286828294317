import {canShowCartButton} from "../../../menuService";
import * as styled from "./ViewOrderButton.styled";
import {selectTable} from "../../../../vendor/vendorSlice";
import {useEffect, useState} from "react";
import {selectCart} from "../../../../cart/cartSlice";
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {Translate} from "../../../../intl/translator";
import {Cart} from "../../../../cart/Cart";
import {setOpenSidebar, SideBarOptions} from "../../../../core/panelsSlice";

export function ViewOrderButton() {
    const strings = {
        s_view_order: Translate('view_order')
    }
    const cartSliceState = useAppSelector(selectCart);
    const table = useAppSelector(selectTable);
    const [cart, setCart] = useState<Cart>(new Cart({items: []}));
    const dispatch = useAppDispatch();

    useEffect(() => {
        setCart(new Cart(cartSliceState))
    },[cartSliceState])

    const openCart = () => {
        dispatch(setOpenSidebar(SideBarOptions.CART));
    }

    return (
        <styled.ViewOrderButton
            visible={
                canShowCartButton({
                    cartItemsLength: cart.itemsCount(),
                    table
                })
            }>
            <div className="button" onClick={openCart}>
                <div className="flex content-center items-center">
                    <div className="count">{cart.itemsCount()}</div>
                    <p className="text-lg">{strings.s_view_order}</p>
                </div>
                <div>
                    <p className="text-lg">&euro;{cart.displayFormattedPrice()}</p>
                </div>
            </div>

        </styled.ViewOrderButton>
    )
}
