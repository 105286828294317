import {Cart} from "../../../Cart";
import {CartItem} from "../../../CartItem";
import {CartScreenItem} from "./CartScreenItem";
import {Translate} from "../../../../intl/translator";

interface Props {
    cart: Cart
}

export function CartOrderItems({cart} : Props) {
    const strings = {
        s_order_items: Translate('order_items'),
        s_empty_cart: Translate('cart_is_empty'),
        s_cart_screen_description: Translate('cart_screen_description'),
    };

    return (
        <>
            <div className="flex indent place-content-between pb-5">
                <div>
                    <h2 className="text-xl fore-color">{strings.s_order_items}</h2>
                    <p>{strings.s_cart_screen_description}</p>
                </div>
            </div>
            <hr className="py-3"/>

            <div className="indent pb-8">
                {cart.Items.map(item => {
                    const cartItem = new CartItem(item);
                    return <CartScreenItem key={item.Id} item={cartItem}/>
                })}
                {cart.Items.length === 0 &&
                    <p className="text-2xl text-gray-300 text-center">{strings.s_empty_cart}</p>
                }
            </div>
        </>
    )
}
