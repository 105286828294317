import styled from "styled-components";

interface LightboxI {
    open: boolean
}

const Lightbox = styled.aside<LightboxI>`
  background: var(--theme-modded-bg-color);
  .backdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    ${props => props.open && `pointer-events: all;`}
    ${props => props.open ? `background: rgba(0,0,0,0.8);` : `background: none;`}
    z-index: 99999;
    transition: all 200ms;

    
  }
  .inner{
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    height: 80vh;
    //right: -415px;
    bottom: -100vh;
    max-width: 415px;
    //background: var(--theme-modded-bg-color);
    transition: bottom 200ms ;
    //box-shadow: 0 1px 2px rgba(0,0,0,0.07),
    //0 2px 4px rgba(0,0,0,0.07),
    //0 4px 8px rgba(0,0,0,0.07),
    //0 8px 16px rgba(0,0,0,0.07),
    //0 16px 32px rgba(0,0,0,0.07),
    //0 32px 64px rgba(0,0,0,0.07);
    z-index: 999999;
    ${props => props.open && `top: 50%;`}
    ${props => props.open && `transform: translate(-50%, -50%);`}
    &::-webkit-scrollbar{
      display: none;
    }
    scrollbar-width: none;
    @media(max-width: 500px) {
      left: 0;
      top: 10vh;
      height: 90vh;
      max-width: 100vw;
      transform: translateX(0%);
    }
  }
`

export {Lightbox}
