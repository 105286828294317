import {
    MenuCategoryI, MenuItemExtraDetailsI,MenuItemI, ProductViewI
} from "./menuInterfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {MenuItem} from "./MenuItem";

interface MenuState {
    items: Array<MenuCategoryI>,
    itemList: Array<MenuItemI>,
    itemSelected: MenuItemI| null,
    productView: ProductViewI,
    favourites: Array<MenuItem>,
}

const initialState: MenuState = {
    items: [],
    itemList: [],
    itemSelected: null,
    productView: {
        mode: 'product',
        open: false
    },
    favourites: [],
}

export const menuSlice = createSlice({
    name: 'menuSlice',
    initialState,
    reducers: {
        setMenu: (state, action: PayloadAction<Array<MenuCategoryI>>) => {
            state.items = action.payload
        },
        setMenuList: (state, action: PayloadAction<Array<MenuItemI>>) => {
            state.itemList = action.payload
        },
        setItemSelected: (state, action: PayloadAction<MenuItemI | null>) => {
            state.itemSelected = action.payload;
        },
        setProductView: (state, action: PayloadAction<ProductViewI>) => {
            state.productView = action.payload;
        },
        setItemSelectedExtraDetails: (
            state,
            action: PayloadAction<MenuItemExtraDetailsI>
        ) => {
            if(state.itemSelected){
                state.itemSelected.Gallery = action.payload.gallery;
                state.itemSelected.Allergens = action.payload.allergens;
                state.itemSelected.Variations = action.payload.variations;
            }
        },
        setFavourites: (state, action: PayloadAction<Array<MenuItem>>) => {
            state.favourites = action.payload;
        }
    }
})

export const {setMenu, setMenuList, setItemSelected, setProductView, setItemSelectedExtraDetails, setFavourites} = menuSlice.actions;
export default menuSlice.reducer;
export const selectMenu = (state: RootState) => state.menu.items;
export const selectProductView = (state: RootState) => state.menu.productView;
export const selectFavourites = (state: RootState) => state.menu.favourites;
export const selectMenuItemsList = (state: RootState) => state.menu.itemList;
export const selectItemSelected = (state: RootState) => {
    if(state.menu.itemSelected){
        return new MenuItem(state.menu.itemSelected);
    }else{
        return null;
    }
};
