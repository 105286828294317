import styled from "styled-components";

interface FormButtonI{
    background?: string
    disable?: boolean
    width?: number
}

const FormButton = styled.div<FormButtonI>`
  color: white;
  background: ${props => props.background ? props.background : "var(--theme-primary-color)"};
  border-radius: 28px;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  transition: all 200ms;
  &:hover{
    filter: brightness(110%);
  }
  ${props => props.disable && `background: grey; pointer-events: none; `}
  ${props => props.width && `width:`+props.width+`px;`}
`;

interface AppInputFieldI{
    border?: string
}

const AppInputField = styled.input<AppInputFieldI>`
  width: 100%;
  border: ${props => props.border ? props.border : "solid thin black"};
  border-radius: 10px;
  height: 65px;
  margin: 5px 0;
  padding-left: 10px;
  font-size: 20px;
  :disabled{
    border: none;
    padding-left: 0;
  }
  &.small{
    height: 35px;
    font-size: 16px;
    border-radius:5px;
  }
`;

export {FormButton, AppInputField};
