import {Lightbox} from "../../../core/components/Lightbox/Lightbox";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {LightboxOptions, selectOpenLightbox, setOpenLightbox} from "../../../core/panelsSlice";
import {selectFavourites, selectItemSelected, selectProductView, setFavourites, setItemSelected, setProductView} from "../../menuSlice";
import {useEffect, useState} from "react";
import {selectVendor} from "../../../vendor/vendorSlice";
import {CartItem} from "../../../cart/CartItem";
import {selectUser} from "../../../user/userSlice";
import {MenuItem} from "../../MenuItem";
import {userFavouriteProductToggle} from "../../../user/userRepository";
import {MenuItemI} from "../../menuInterfaces";
import {ProductStickyHeader} from "./ProductStickyHeader";
import {ProductScreenHero} from "./ProductScreenHero";
import * as styled from "./Product.styled";
import CONFIG from "../../../../app/config";
import {UserStatus} from "../../../user/userInterfaces";
import * as ui from '../../../theme/styled-components/UI.styled'
import {Heart} from "akar-icons";
import {ProductAllergens} from "./ProductAllergens";
import {canShowProductKitchenNotes} from "../../menuService";
import {ProductComments} from "./ProductComments";
import {ProductVariations} from "./Variations/ProductVariations";
import {ProductStickyFooter} from "./ProductStickyFooter";

export function Product() {
    const openLightbox = useAppSelector(selectOpenLightbox);
    const dispatch = useAppDispatch();
    const itemSelected = useAppSelector(selectItemSelected);
    const productView = useAppSelector(selectProductView);
    const vendor = useAppSelector(selectVendor);
    const favourites = useAppSelector(selectFavourites);
    const user = useAppSelector(selectUser);
    const [loaded, setLoaded] = useState(false);
    const [cartItem, setCartItem] = useState<CartItem>();


    useEffect(() => {
        if (itemSelected) {
            initCartItem(itemSelected);
            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productView])

    const initCartItem = (product: MenuItem) => {
        // if is update cart item
        if (productView.mode === "cart" && productView.cartItem) {
            setCartItem(new CartItem(productView.cartItem));
        } else {
            setCartItem(new CartItem({Product: product}));
        }

    }

    const close = () => {
        setLoaded(false);
        dispatch(setOpenLightbox(LightboxOptions.CLOSED))
        setTimeout(() => {
            dispatch(setItemSelected(null));
            dispatch(setProductView({
                open: false,
                mode: "product",
                cartItem: undefined
            }))

        }, 200)

    }

    const addAnother = (item: MenuItem) => {
        dispatch(setItemSelected(null));
        dispatch(setProductView({
            open: false,
            mode: "product",
            cartItem: undefined
        }))
        setLoaded(false);
        dispatch(setItemSelected(item));
        dispatch(setProductView({
            open: true,
            mode: "product",
            cartItem: undefined
        }))
    }

    const toggleFavourite = async () => {
        if (itemSelected) {
            if (isFavourite()) {
                let favs = favourites.filter((item: MenuItemI) => {
                    return item.MenuItemID !== itemSelected.MenuItemID
                })
                dispatch(setFavourites(favs));
            } else {
                dispatch(setFavourites([...favourites, itemSelected]))
            }
            await userFavouriteProductToggle(user.RegisteredUserID, itemSelected.MenuItemID)
        }
    }

    const isFavourite = (): boolean => {
        let i = favourites.findIndex((item: MenuItemI) => {
            return item.MenuItemID === itemSelected?.MenuItemID
        })
        return i >= 0;
    }
    return (
        <Lightbox open={openLightbox === LightboxOptions.PRODUCT} close={close}>
            <>
                {loaded &&
                    <styled.ProductContainer id="product-screen-container">
                        <ProductStickyHeader title={itemSelected ? itemSelected.ItemName : ""} close={close}/>
                        <ProductScreenHero close={close}
                                           gallery={itemSelected?.Gallery || []}
                                           cover={itemSelected?.FileName}
                        />
                        <div className="indent pt-4 card-modded-bg">
                            <div className="flex place-content-between">
                                <h3 className="flex-1 fore-color text-xl">
                                    {itemSelected?.ItemName}
                                </h3>
                                {CONFIG.ENABLE_FAVOURITES && user.Verified === UserStatus.Verified &&
                                    <ui.ActionButton onClick={toggleFavourite}>
                                        <Heart color={isFavourite() ? "red" : "grey"} fill={isFavourite() ? "red" : "white"}/>
                                    </ui.ActionButton>
                                }
                            </div>
                            {itemSelected?.Price !== '-' &&
                                <styled.ProductScreenPrice className="mt-3">
                                    <span>&euro;</span>{Number(itemSelected?.Price).toFixed(2)}
                                </styled.ProductScreenPrice>
                            }
                            <p className='pt-3 fore-color'>{itemSelected?.Description}</p>
                            <ProductAllergens items={itemSelected?.Allergens || []}/>
                        </div>
                        {cartItem &&
                            <div className="card-modded-bg" style={{marginTop: "-1px"}}>
                                <div className="pt-6 indent">
                                    <ProductVariations cartItem={cartItem} setCartItem={setCartItem}/>
                                </div>
                                {canShowProductKitchenNotes({vendor}) &&
                                    <ProductComments cartItem={cartItem} setCartItem={setCartItem}/>
                                }

                                {productView.mode === "cart" && itemSelected &&
                                    <div onClick={() => {
                                        addAnother(itemSelected)
                                    }} className="mt-3 indent">
                                        <div className="items-center">
                                            <p className="text-lg text-left underline w-full fore-color cursor-pointer">
                                                Add Another
                                            </p>
                                        </div>
                                    </div>
                                }

                                <div style={{height: '100px'}}/>
                                <ProductStickyFooter cartItem={cartItem} setCartItem={setCartItem} closeProduct={close}/>
                            </div>


                        }
                    </styled.ProductContainer>
                }
            </>
        </Lightbox>
    )
}

