import {BottomSheet, BottomSheetRef} from "react-spring-bottom-sheet";
import React, {useRef} from "react";
import {Order} from "../../Order";
import {SubmitHandler, useForm} from "react-hook-form";
import {Translate} from "../../../intl/translator";
import {AppInputField, FormButton} from "../../../theme/styled-components/Form.styled";

export interface RoomChargeBottomSheetState{
    open: boolean,
    order?: Order
}

interface RoomChargeBottomSheetI{
    sheetState: RoomChargeBottomSheetState,
    setSheetState: any,
    setLoading: any,
    submitOrder: any
}

interface Inputs {
    roomNo: string,
}

export function RoomChargeBottomSheet({sheetState, setSheetState, setLoading, submitOrder}:RoomChargeBottomSheetI) {
    const strings = {
        s_title: Translate('room_payment_sheet_title'),
        s_error: Translate('room_payment_sheet_error'),
        s_button: Translate('room_payment_sheet_button'),
    }
    const sheetRef = useRef<BottomSheetRef>(null);
    const {register, handleSubmit,reset, formState: {errors}} = useForm<Inputs>();


    const submit: SubmitHandler<Inputs> = data => {
        if(sheetState.order){
            let order: Order = sheetState.order;
            order.info.Roomnum = data.roomNo;
            reset()
            setSheetState({open: false});
            submitOrder(order)

        }
    }

    return (
        <BottomSheet
            open={sheetState.open}
            ref={sheetRef}
            onDismiss={() => {
                reset();
                setSheetState({open: false});
                setLoading(false);
            }}
            defaultSnap={({maxHeight}) => {
                return maxHeight - maxHeight / 2
            }}
            snapPoints={({maxHeight}) => [
                maxHeight - maxHeight / 2,
            ]}

        >
            <form onSubmit={handleSubmit(submit)} className="form indent mt-8">
                <h1 className="text-center row-start-1 col-start-1 col-span-full text-2xl fore-color">{strings.s_title}</h1>
                <AppInputField type={"number"}
                               {...register("roomNo", {
                                   required: true

                               })}/>
                {errors.roomNo && <span className="text-red-600">{strings.s_error}</span>}
            </form>
            <div className="flex justify-center items-center mt-8">
                <FormButton onClick={handleSubmit(submit)}>
                    {strings.s_button}
                </FormButton>
            </div>


        </BottomSheet>
    )
}
