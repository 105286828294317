import axios from "axios";
import CONFIG from "../../app/config";
import {
    MenuCategoryI,
    MenuItemAllergenI, MenuItemExtraDetailsI,
    MenuItemGalleryI, MenuItemI
} from "./menuInterfaces";
import {LocaleInterface} from "../intl/intlSlice";

export interface GetMenuI {
    catalog: Array<MenuCategoryI>,
    list: Array<MenuItemI>
}
export function getMenu(vendorId: string, localeId: string, userId?: string) {
    return new Promise<GetMenuI>((resolve,reject) => {
        axios.get(`${CONFIG.API_URL}/menu?vendorId=${vendorId}&localeId=${localeId}&userId=${userId}`)
            .then(res => {
                if(res.status === 200) {
                    const menu :GetMenuI = res.data;
                    resolve(menu);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getMenuItemGallery(itemId: string) {
    return new Promise<Array<MenuItemGalleryI>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/menu/item/gallery?itemId=${itemId}`)
            .then(res => {
                if(res.status === 200) {
                    const gallery :Array<MenuItemGalleryI> = res.data;
                    resolve(gallery);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getMenuItemAllergens(itemId: string, locale: LocaleInterface) {
    return new Promise<Array<MenuItemAllergenI>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/menu/item/allergens?itemId=${itemId}&localeId=${locale.LanguageID}`)
            .then(res => {
                if(res.status === 200) {
                    const allergens :Array<MenuItemAllergenI> = res.data;
                    resolve(allergens);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}


export function getMenuItem(itemId: string, locale: LocaleInterface) {
    return new Promise<MenuItemExtraDetailsI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/menu/item?itemId=${itemId}&localeId=${locale.LanguageID}`)
            .then(res => {
                if(res.status === 200) {
                    const result :MenuItemExtraDetailsI = res.data;
                    resolve(result);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}
