import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {LocaleInterface, selectAvailableLocales, selectLocale, setLocale} from "../../../../intl/intlSlice";
import {selectVendor} from "../../../../vendor/vendorSlice";
import {selectUser} from "../../../userSlice";
import {useEffect} from "react";
import {getMenu} from "../../../../menu/menuRepository";
import {setMenu, setMenuList} from "../../../../menu/menuSlice";
import {LocaleButton} from "./LocalePicker.styled";

export function LocalePicker() {
    const availableLocales = useAppSelector(selectAvailableLocales);
    const activeLocale = useAppSelector(selectLocale);
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    useEffect(() => {
        getMenu(vendor.OutletID, activeLocale.LanguageID, user.RegisteredUserID).then(menu => {
            dispatch(setMenu(menu.catalog));
            dispatch(setMenuList(menu.list));
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLocale])

    return (
        <div className="flex py-5 px-5 place-content-evenly">
            {availableLocales.map((locale: LocaleInterface) => {
                return <LocaleButton
                    key={locale.ShortName}
                    isActive={activeLocale.ShortName === locale.ShortName}
                    onClick={() => dispatch(setLocale(locale))}
                    src={`/images/ui/locales/flag_${locale.ShortName}.svg`}
                />
            })}
        </div>
    );
}
