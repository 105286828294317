import axios from "axios";
import CONFIG from "../../app/config";
import {LocaleInterface} from "./intlSlice";

export function getAvailableLocales() {
    return new Promise<Array<LocaleInterface>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/intl/locales`)
            .then(res => {
                if(res.status === 200) {
                    const locales : Array<LocaleInterface> = res.data;
                    resolve(locales);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}
