import styled from "styled-components";
import {MediaQueries} from "../../../theme/styles/queries";

interface SideBarStyledI {
    open: boolean
}
const SideBar = styled.aside<SideBarStyledI>`
  background: var(--theme-modded-bg-color);
  .backdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    ${props => props.open && `pointer-events: all;`}
    ${props => props.open ? `background: rgba(0,0,0,0.1);` : `background: none;`}
    z-index: 99;
    transition: all 200ms;
  }
  .inner{
    position: fixed;
    height: 100vh;
    right: -415px;
    top: 0;
    width: 415px;
    background: var(--theme-modded-bg-color);
    transition: right 200ms ;
    box-shadow: 0 1px 2px rgba(0,0,0,0.07),
    0 2px 4px rgba(0,0,0,0.07),
    0 4px 8px rgba(0,0,0,0.07),
    0 8px 16px rgba(0,0,0,0.07),
    0 16px 32px rgba(0,0,0,0.07),
    0 32px 64px rgba(0,0,0,0.07);
    z-index: 100;
    ${props => props.open && `right: 0px`}
    ${props => !props.open && `box-shadow: none`}
  }
  @media screen and ${MediaQueries.portrait} {
    .inner{
      width: 100vw;
      right: -100vw;
      ${props => props.open && `right: 0px`}
    }
  }
  
`;

export {SideBar}
