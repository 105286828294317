import styled, {css} from "styled-components";
import {MediaQueries} from "../../../theme/styles/queries";

export enum VendorLogoPosition{
    'Card',
    'Header'
}

interface VendorLogoI {
    placement: VendorLogoPosition
}

const VendorLogo = styled.img<VendorLogoI>`

  ${props => props.placement === VendorLogoPosition.Card && css`
    width: 150px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto -87px;
  `}
  ${props => props.placement === VendorLogoPosition.Header && css`
    width: 235px;
    border-radius: 50%;
    position: absolute;
    right: 100px;
    top: calc(163px - 117px);
    aspect-ratio: 1;
    @media screen and ${MediaQueries.portrait} {
      width: 90px;
      border: solid 4px white;
      position: absolute;
      top: unset;
      bottom: -45px;
      right: 15px;
    }
  `}
`


export {VendorLogo}
