export interface UserI {
    RegisteredUserID: string,
    RegistrationTypeID?: string,
    PaymentTypeID?: string,
    Name?: string,
    Surname?: string,
    Username?: string,
    Email?: string,
    PhoneNum?: string,
    PhoneCode?: string,
    Verified: string,
    CompletedOrdersCount?: number,
    FirebaseToken?: string
    Addresses: Array<UserAddressI>,
    Address?: UserAddressI
}

export interface UserAddressI {
    LocationID: string,
    RegisterUserID: string,
    RoadName: string,
    Number: string,
    FlatNo?: string,
    PostalCode: string,
    Municipality: string,
    District: string,
    FloorNumber?: string,
    BuildingName?: string,
    Details?: string,
    NickName?: string,
    Latitude?: string,
    Longitude?: string,
    ValidForDelivery?: string,
}

export interface PostalAddressI {
    ID: string,
    RoadNameGr: string
    RoadNameEn: string
    From_To?: string
    PostalCode: string
    MunicipalityGr: string
    MunicipalityEn: string
    DistrictGr: string
    DistrictEn: string
}

export interface CompanyDepartmentI {
    Name: string,
    Code: string,
    CompanyUserID: string,
    CompanyID: string,
    DepartmentID: string
}

export interface CompanyUserI {
    RegisteredUserID: string,
    Admin: string,
    PhoneNum: string,
    Department: Array<CompanyDepartmentI>
}

export enum UserStatus {
    Verified = "1",
    UnVerified = "0"
}
