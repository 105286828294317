import styled from "styled-components";


interface ProductStickyHeaderContainerI {
    show: boolean
}

const ProductContainer = styled.div`
  max-width: 400px;
  min-width: 400px;
  height: 80vh;
  overflow-y: scroll;
  overscroll-behavior: contain;
  scrollbar-width: none;
  background: var(--theme-modded-bg-color);
  border-radius: 17px 17px 0 0;
  &::-webkit-scrollbar {
    width: 1px;
  }
  @media(max-width: 500px){
    max-width: 100%;
    min-width: 360px;
  }

  //background: white;
`

const ProductStickyHeaderContainer = styled.div<ProductStickyHeaderContainerI>`
  width: 100%;
  min-height: ${props => props.show ? '70px' : 0};
  max-height: ${props => props.show ? '200px' : 0};
  overflow: hidden;
  background-color: #343434;
  position: absolute;
  z-index: 3;
  border-radius: 17px 17px 0 0;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1px;
`;

const ProductScreenCover = styled.div`
  width: 100%;
  aspect-ratio: 1024/512;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    flex-shrink: 0;
    min-width: 400px;
    min-height: 100%;
    object-fit: cover;
  }
`;

const ProductScreenPrice = styled.div`
  color: var(--theme-primary-color);
  font-size: 31px;

  span {
    font-size: 20px;
  }
`

interface ProductVariationPriceI {
    active?: boolean
}

const ProductVariationPrice = styled.div<ProductVariationPriceI>`
  color: ${props => props.active ? 'var(--theme-modded-fore-color)' : 'var(--theme-grey-color)'};
  width: 100px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

interface ProductVariationQuantityI {
    round?: boolean,
    populated?: boolean
}

const ProductVariationQuantity = styled.div<ProductVariationQuantityI>`
  width: 25px;
  aspect-ratio: 1;
  border-radius: ${props => props.round ? '50%' : '3px'};
  border: solid thin #ccc;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: white;
  background-color: ${props => props.populated ? 'var(--theme-primary-color)' : 'var(--theme-modded-bg-color)'};
  font-size: 14px;
  transition: background-color 50ms ease-in;
  cursor: pointer;

  span {
      //height: ${props => props.populated ? '18px' : 0};
    overflow: hidden;
    transition: all 250ms;
    transform: scale(${props => props.populated ? 1 : 0});
  }
`;

interface ProductAddToCartContainerI {
    disable?: boolean
}

const ProductAddToCartContainer = styled.div<ProductAddToCartContainerI>`
  background-color: ${props => props.disable ? 'grey' : "var(--theme-primary-color)"};
  height: 54px;
  border-radius: 27px;
  min-width: 230px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const ProductStickyFooterContainer = styled.div`
  position: sticky;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  z-index: 3;
  bottom: 0;
  display: flex;
  background-color: var(--theme-modded-sticky-bg);
  align-items: center;
  justify-content: center;
`;

export {
    ProductStickyHeaderContainer,
    ProductScreenCover,
    ProductContainer,
    ProductScreenPrice,
    ProductVariationPrice,
    ProductVariationQuantity,
    ProductAddToCartContainer,
    ProductStickyFooterContainer
}
