import * as StyledTheme from "./../../../theme/styled-components/Theme.styled";
import {FormButton} from "../../../theme/styled-components/Form.styled";
import {Footer} from "../../../theme/copmponents/Footer";
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import {useEffect, useState} from "react";
import 'react-phone-number-input/style.css'
import {userLogin, userSendOtp} from "../../userRepository";
import {useAppDispatch} from "../../../../app/hooks";
import {initUser} from "../../userSlice";
import {UserStatus} from "../../userInterfaces";
import {getUserOTPValidationStatus} from "../../userService";
import {OtpForm} from "../OtpForm/OtpForm";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../intl/translator";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronLeft} from "react-icons/bs";

const LoginScreen = () => {
    const strings = {
        s_title: Translate('sign_in_title'),
        s_description: Translate('sign_in_description'),
        s_label: Translate('mobile_number'),
        s_button: Translate('next'),
        s_back: Translate('back')
    }
    const [value, setValue] = useState("");
    const [otpOpen, setOtpOpen] = useState(false);
    const [otpTimeout, setOtpTimeout] = useState(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        let otpInterval = setInterval(() => {
            if (otpTimeout > 0) {
                setOtpTimeout(otpTimeout - 1);
            }
        }, 1000)
        return () => {
            clearInterval(otpInterval)
        }
    })

    const login = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        let pn = parsePhoneNumber(value);
        if (pn) {
            const phoneCode = "+" + pn.countryCallingCode;
            const phone = pn.nationalNumber;
            userLogin(phone, phoneCode).then(user => {
                dispatch(initUser(user));
                if (user.Verified === UserStatus.Verified) {
                    // setUserOTPValidationStatus(user.PhoneNum?.toString() ?? phone);
                    if (getUserOTPValidationStatus(user.PhoneNum?.toString() ?? phone)) {
                        navigate('/delivery/type');
                    } else {
                        setOtpTimeout(60);
                        userSendOtp(phone, phoneCode).then(() => {
                            setOtpOpen(true);
                        })
                    }
                } else {
                    navigate('/register');
                }
            })
        }
    }

    const closeOtp = () => {
        setOtpOpen(false);
    }

    return (
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage >
                <div className="actions indent  py-8">
                    <div className="flex items-center">
                        {otpOpen &&
                            <div className="flex items-center cursor-pointer" onClick={closeOtp}>
                                <ActionButton>
                                    <BsChevronLeft size={24}/>
                                </ActionButton>
                                <div className="pl-8">Change Number</div>
                            </div>
                        }


                    </div>
                </div>
            </StyledTheme.SizedContentPage>
            <Footer>
                <div>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card} />
                    </div>
                    <div className='card-panel'>
                        <div style={{background: "white"}} className="pt-36 px-8 pb-12 md:px-20">
                            {!otpOpen &&
                                <>
                                    <h1 className='text-3xl'>{strings.s_title}</h1>
                                    <p className='mt-6 mb-8 md:mb-16'>
                                        {strings.s_description}
                                    </p>

                                    <p className='mb-2 text-gray-400'>{strings.s_label}</p>
                                    <form onSubmit={login}>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            defaultCountry="CY"
                                            value={value}
                                            onChange={(v) => {
                                                if (v) {
                                                    setValue(v)
                                                }
                                            }}
                                        />
                                    </form>

                                    <FormButton onClick={login} className={'mt-12'}> {strings.s_button} </FormButton>
                                </>
                            }
                            <OtpForm open={otpOpen} setOpen={setOtpOpen} otpTimeout={otpTimeout}
                                     setOtpTimeout={setOtpTimeout}/>
                        </div>
                    </div>
                </div>

            </Footer>
        </div>
    )
}


export default LoginScreen;
