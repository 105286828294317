import styled from "styled-components";
import {MediaQueries} from "../styles/queries";

const FooterWrapper = styled.section`
  min-height: 32vh;
  background: transparent;
  display: flex;
  @media screen and ${MediaQueries.portrait} {
    margin: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
`;


const AppCreditsContainer = styled.div`
  padding: 5px 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 350px;
  justify-content: end;
  margin-bottom: 30px;
  @media screen and ${MediaQueries.portrait} {
    order: 3;
    max-width: 100%;
    min-width: 150px;
    margin-bottom: 0;
    padding: 30px 30px 50px;
  }
  @media screen and ${MediaQueries.tablet} {
    min-width: 150px;
  }
  a{
    text-decoration: none;
    color: white;
    margin-bottom: 10px;
    font-size: 20px;
  }
  span{
    color: #419BDC;
  }
`;

const FooterLogo = styled.div`
  padding: 5px 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 350px;
  justify-content: end;
  margin-bottom: 30px;
  @media screen and ${MediaQueries.portrait} {
    max-width: 100%;
    min-width: 150px;
    order: 2;
    margin-bottom: 0;
    padding: 30px 30px 50px;
  }
  @media screen and ${MediaQueries.tablet} {
    min-width: 150px;
  }
  
`;

export {FooterWrapper, AppCreditsContainer, FooterLogo}
