import styled, {css} from "styled-components";
import {MediaQueries} from "../../../theme/styles/queries";

interface MenuHeaderStyledI {
    background?: string;
}

const Header = styled.section<MenuHeaderStyledI>`
  height: 325px;
  position: relative;
  ${props => props.background && css`
    background-image: url("${props.background}");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `} @media screen and ${MediaQueries.portrait} {
    height: 210px;
  }
`;
Header.defaultProps = {
    background: '#ccc'
}

interface ActionsI {
    left?: boolean;
    right?: boolean;
}

const Actions = styled.div<ActionsI>`
  position: absolute;
  top: 30px;
  width: 180px;
  height: 40px;
  display: flex;
  ${props => props.left && `left: var(--theme-walls-indentation);`}
  ${props => props.right && `right: var(--theme-walls-indentation); justify-content: end;`}
`

export {Header, Actions};
