import { useEffect } from 'react';
import './App.css';
import {store} from "./app/store";
import {saveState} from "./app/browser-storage";
import {Theme} from "./features/theme/copmponents/Theme";
import {Navigate, Route, Routes} from "react-router-dom";
import LoginScreen from "./features/user/components/LoginScreen/LoginScreen";
import {Load} from "./features/load/components/Load";
import {Splash} from "./features/load/components/Splash";
import {MenuScreen} from "./features/menu/components/MenuScreen/MenuScreen";
import {CheckoutScreen} from "./features/cart/components/Checkout/CheckoutScreen";
import {JccGatewayRedirect} from "./features/cart/components/Checkout/gateways/Jcc/JccGatewayRedirect";
import {OrderStatusScreen} from "./features/cart/components/OrderStatus/OrderStatusScreen";
import {DeliveryTypeScreen} from "./features/cart/components/DeliveryTypeScreen/DeliveryTypeScreen";
import AddAddress from "./features/user/components/AddAddress/AddAddress";
import {UserAddresses} from "./features/user/components/UserAddresses/UserAddresses";
import {EditAddressResolver} from "./features/user/components/EditAddress/EditAddressResolver";
import {useAppSelector,useAppDispatch} from "./app/hooks";
import {selectUser} from "./features/user/userSlice";
import CONFIG from "./app/config";
import RegisterScreen from "./features/user/components/RegisterScreen/RegisterScreen";
import {OrderPaymentFailed} from "./features/cart/components/OrderStatus/OrderPaymentFailed";
import {useNavigate} from "react-router-dom";
import {setFavourites, setItemSelected, setMenu, setMenuList} from "./features/menu//menuSlice";
import {unsetVendor} from "./features/vendor/vendorSlice";
import {clearCart} from "./features/cart/cartSlice";
// import {useAppDispatch, useAppSelector} from "../../../app/hooks";
function App() {
    const dispatch = useAppDispatch();
    const doResets = () => {
        dispatch(setItemSelected(null));
        dispatch(unsetVendor);
        dispatch(clearCart());
        localStorage.removeItem('load');
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (window.performance.navigation.type === 1) {
        //   console.log('Page was refreshed');
          doResets();
          navigate('/load');
        }
      }, []);
    store.subscribe(
        () => {
            saveState(store.getState());
        }
    );

    return (
        <Theme>
            <Routes>
                <Route path="/:urlId" element={<Load/>}/>
                <Route path="/load" element={<Load/>}/>
                <Route path="/splash" element={<Splash/>}/>
                <Route path="/menu" element={<MenuScreen/>}/>
                <Route path="/checkout" element={<Protected route={<CheckoutScreen/>} />}/>
                <Route path="/jccPayment/:orderId/:accountId/:orderTotal" element={<JccGatewayRedirect />} />
                <Route path="/order" element={<Protected route={<OrderStatusScreen/>} />}/>
                <Route path="/order/failed" element={<OrderPaymentFailed />} />
                <Route path="/order/failed/:orderId" element={<OrderPaymentFailed />} />
                <Route path="/order/:orderId/:payId" element={<OrderStatusScreen />} />
                {/* <Route path="/delivery/type" element={<DeliveryTypeScreen/>}/> */}
                <Route path="/delivery/type" element={<Protected route={<DeliveryTypeScreen/>} />}/>
                <Route path="/address/add" element={<Protected route={<AddAddress/>} />}/>
                <Route path="/address/edit/:id" element={<Protected route={<EditAddressResolver/>} />}/>
                <Route path="/address/list" element={<Protected route={<UserAddresses/>} />}/>
                <Route path="/login" element={<LoginScreen/>}/>
                <Route path="/register" element={<RegisterScreen/>}/>
                <Route path="/" element={<LoginScreen />} />
            </Routes>
        </Theme>

    );
}

type Props = {
    route: JSX.Element
}
function Protected({route}:Props) {
    const user = useAppSelector(selectUser);
    return (
        <>
            {user && user.RegisteredUserID !== CONFIG.GUEST_USER_ID &&
                route
            }
            {(!user || user.RegisteredUserID === CONFIG.GUEST_USER_ID) &&
                <Navigate to={'/login'} />
            }
        </>
    )
}

export default App;
