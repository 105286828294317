import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";

export interface ThemeSliceInterface {
    mode: string,
    browserMode?: string,
    userSpecifiedMode: boolean,
    vendorColor: string
}

const initialState: ThemeSliceInterface = {
    mode: 'light',
    userSpecifiedMode: false,
    vendorColor: 'E84D39'
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<string>) => {
            if(!state.userSpecifiedMode) {
                state.mode = action.payload;
            }
        },
        setSpecifiedMode: (state, action: PayloadAction<string>) => {
            state.mode = action.payload;
            state.userSpecifiedMode = true;
        },
        setVendorColor: (state, action: PayloadAction<string>) => {
            state.vendorColor = action.payload;
        },
    }
})

export const {setMode, setVendorColor, setSpecifiedMode} = themeSlice.actions;
export default themeSlice.reducer;
export const selectMode = (state: RootState) => state.theme.mode;
export const selectVendorColor = (state: RootState) => state.theme.vendorColor;
