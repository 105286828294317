import React from 'react';
import {BsClock} from "react-icons/bs";
import {FiGlobe, FiMapPin, FiPhoneCall} from "react-icons/fi";
import {AiOutlineMail} from "react-icons/ai";
import {useAppSelector} from "../../../app/hooks";
import {selectVendor, selectVendorSchedule} from "../vendorSlice";
import {Collapsible} from "../../core/components/Collapsible/Collapsible";
import {VendorOpenStatusPill} from "./VendorOpenStatusPill";

export function VendorDetails() {
    const vendor = useAppSelector(selectVendor);
    const vSchedule = useAppSelector(selectVendorSchedule);

    return (
        <div className="vendor-details-collapsible mb-3">
            <Collapsible title={vendor.Name} open={false}>
                <>
                    <p className="mb-3">
                        <BsClock className="inline-block mr-3"/>{vSchedule.working_hours}
                    </p>
                    {vendor.Address &&
                        <p className="mb-3">
                            <FiMapPin className="inline-block mr-3"/>
                            {vendor.getShopMapUrl().isSet &&
                                <a target="_blank" className="primary-color"
                                   href={vendor.getShopMapUrl().url} rel="noreferrer">
                                    {vendor.Address}
                                </a>
                            }
                            {!vendor.getShopMapUrl().isSet && vendor.Address}
                        </p>
                    }
                    {vendor.PhoneNumber &&
                        <p className="mb-3">
                            <FiPhoneCall className="inline-block mr-3"/>
                            <a target="_blank" rel="noreferrer"  className="primary-color"
                               href={"tel:" + vendor.PhoneNumber}>
                                {vendor.PhoneNumber}
                            </a>
                        </p>
                    }
                    {vendor.URL &&
                        <p className="mb-3">
                            <FiGlobe className="inline-block mr-3"/>
                            <a target="_blank" rel="noreferrer" className="primary-color"
                               href={"https://" + vendor.URL}>{vendor.URL}</a>
                        </p>
                    }
                    {vendor.Email &&
                        <p className="mb-3">
                            <AiOutlineMail className="inline-block mr-3"/>
                            <a target="_blank" rel="noreferrer"  className="primary-color"
                               href={"mailto:" + vendor.Email}>{vendor.Email}</a>
                        </p>
                    }
                    <VendorOpenStatusPill />
                </>
            </Collapsible>
        </div>
    );
}
