import * as styled from './UserAddresses.styled';
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChatRightDots} from "react-icons/bs";
import {IoSettingsOutline} from "react-icons/io5";
import {UserAddressI} from "../../userInterfaces";
import {Menu, Transition, Popover} from '@headlessui/react'
import {MdEditNote} from "react-icons/md";
import {RiDeleteBinLine} from "react-icons/ri";
import {Fragment} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectUser, setUserAddress, setUserAddresses} from "../../userSlice";
import {selectVendor} from "../../../vendor/vendorSlice";
import {deleteUserAddress, listUserAddresses} from "../../userRepository";
import {useNavigate} from "react-router-dom";
import {FormButton} from "../../../theme/styled-components/Form.styled";

interface Props {
    address: UserAddressI
}

export function AddressCard({address}: Props) {
    const user = useAppSelector(selectUser);
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const deleteAddress = () => {
        deleteUserAddress(user.RegisteredUserID, address.LocationID)
            .then(res => {
                listUserAddresses(user.RegisteredUserID, vendor.OutletID)
                    .then(res => {
                        dispatch(setUserAddresses(res));
                    })
            })
    }
    const editAddress = () => {
        navigate('/address/edit/'+address.LocationID)
    }

    const selectAddress = () => {
        dispatch(setUserAddress(address));
        navigate('/menu');
    }

    return (
        <styled.AddressCard>
            <div className="header">
                <div className="actions">

                    <Menu as="div" className="relative inline-block text-left">
                        <Menu.Button>
                            <ActionButton>
                                <IoSettingsOutline size="16"/>
                            </ActionButton>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({active}) => (
                                            <button
                                                onClick={editAddress}
                                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm ${active ? 'bg-sky-100' : 'text-gray-900'}`}
                                            >
                                                <MdEditNote size={24} className={'mr-4'}/>
                                                <span className="text-lg">Edit</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({active}) => (
                                            <button
                                                onClick={deleteAddress}
                                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm ${active ? 'bg-sky-100' : 'text-gray-900'}`}
                                            >
                                                <RiDeleteBinLine size={20} className={'mr-4 text-red-600'}/>
                                                <span className="text-lg text-red-600">Delete</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>

                    </Menu>
                </div>
                <div className="status">
                    {address.ValidForDelivery === "1" &&
                        <span className="text-green-500 font-medium">Delivery Available!</span>
                    }
                    {address.ValidForDelivery !== "1" &&
                        <span className="text-gray-400">Delivery Unavailable!</span>
                    }
                </div>
                <div className="comments">
                    {address.Details &&
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button>
                                        <ActionButton>
                                            <BsChatRightDots size="16"/>
                                        </ActionButton>
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="
                                absolute
                                w-64
                                right-0
                                z-10 mt-3
                                lg:-translate-y-0.5
                                transform
                                px-4
                                sm:px-0
                                lg:max-w-1xl">
                                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="relative bg-white p-7">
                                                    {address.Details}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    }


                </div>
            </div>
            <div className="content">
                <div className="flex-grow">
                    <p>{address.District}, {address.PostalCode}</p>
                    <p>{address.RoadName} {address.Number}</p>
                    <p>{address.BuildingName}</p>
                    {address.FloorNumber &&
                        <p>Floor {address.FloorNumber} #{address.FlatNo}</p>
                    }
                </div>
                <FormButton
                    disable={address.ValidForDelivery !== "1"}
                    className={'mt-4'}
                    background={'var(--theme-sky-blue)'}
                    onClick={selectAddress}>
                    {address.ValidForDelivery === "1" ? 'Select' : 'Unavailable'}
                </FormButton>

            </div>
        </styled.AddressCard>
    )
}
