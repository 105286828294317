import styled from "styled-components";

const AddAddress = styled.div`
  background: white;
  h1{
    font-size: 2rem;
    margin:  0 auto 30px;
  }
  p{
    margin:  0 auto 30px;
  }
  .step-1{
    max-width: 600px;
    h1, p{
      text-align: center;
    }
  }
  
  .step-2{
    padding: 0 45px;
    
    .input-wrapper{
      position: relative;
      input{
        padding-left: 50px;
      }
      .search-icon{
        position: absolute;
        top: 25px;
        left: 10px;
        color: grey;
      }
      .close-icon{
        position: absolute;
        top: 25px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
  .step-3{
    padding: 0 55px;
  }
  
  @media(max-width: 600px){
    h1{
      font-size: 2rem;
    }
    .step-2, .step-3{
      padding: 0 10px;
    }
  }
`;

interface PostCodeResultsI {
    top: number,
    left: number,
    rectWidth: number,
    visible: boolean
}
const PostCodeResults = styled.div<PostCodeResultsI>`
  position: absolute;
  z-index: 999;
  top: ${props => props.top ? props.top + 2 : 0}px;
  left: ${props => props.left ? props.left : 0}px;
  width: ${props => props.rectWidth ? props.rectWidth : 0}px;
  background: white;
  height: ${props => props.visible ? 200 : 0}px;
  transition: all 250ms;
  //overflow: scroll;
  .wrapper {

  }

  .address-list {
    height: ${props => props.visible ? 175 : 0}px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 0px;
      border-radius: 0px;
    }

    ::-webkit-scrollbar-thumb {
      //-webkit-border-radius: 10px;
      //border-radius: 10px;
      background: var(--theme-sky-blue);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
      background: var(--theme-sky-blue);
    }

    padding-bottom: 30px;
  }

  .address-list-item {
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: var(--theme-sky-blue);
      color: white;
    }
  }
`;

export {AddAddress, PostCodeResults}
