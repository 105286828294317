import styled from "styled-components";

interface ViewOrderButtonI {
    visible?: boolean
}

const ViewOrderButton= styled.div<ViewOrderButtonI>`
  position: fixed;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  z-index: 3;
  bottom: ${props => props.visible ? '0' : '-1000px'};
  
  background-color: var(--theme-modded-sticky-bg);
  align-items: center;
  justify-content: center;
  transition: all 500ms;
  display: none;
  @media(max-width: 960px){
    display: flex;
  }
  .button{
    background-color: var(--theme-primary-color);
    height: 54px;
    border-radius: 27px;
    min-width: 80%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    .count{
      width: 30px;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: white;
      color: black;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export {ViewOrderButton}
