import styled from "styled-components";

const CategoriesBar = styled.div`
  position: sticky;
  position: -webkit-sticky;
  backface-visibility: hidden;
  top: -1px;
  max-width: 1400px;
  padding: 10px 100px;
  margin: auto;
  background: white;
  .categories{
    display: flex;
    align-items: center;
    
  }
  @media(max-width: 1300px) {
    padding: 10px 15px;
  }
  
`;

export {CategoriesBar}
