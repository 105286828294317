import styled from "styled-components";
import {ToastContainer} from "react-toastify";

const OrderScreenHeader = styled.div`
  //background: var(--theme-primary-color);
  background: ${props => props.color ? props.color : "var(--theme-primary-color)"};
  min-height: 250px;
  width: 300px;
  //max-width: 350px;
  border-radius: 0 0 50% 50%;
  margin: -165px auto 30px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 30px;
  flex-direction: column;
  color: white;
  font-size: 20px;
`

const OrderScreenDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "order address";
  .order-details{
    grid-area: order;
  }
  .address{
    grid-area: address;
    text-align: center;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas: "order" "address";
    .address{
      text-align: left;
    }
  }
`;

const OrderStatusScreen = styled.div`
  background: white;
  
  h6{
    color: grey;
    font-size: 14px;
  }
  p{
    font-size: 14px;
  }
`;

const NotificationToast = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    margin: 0 15px;
    @media (max-width: 600px) {
      width: calc(100vw - 30px);
    }
    //
    &.Toastify__toast-container--bottom-center{
      bottom: 20px;
    }
    .Toastify__toast {
      border-radius: 10px;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 10px;
      .Toastify__toast-icon{
        display: none;
      }
      svg{
        margin-top: 14px;
        min-width: 30px;
      }
      &.Toastify__toast--success{
        border: 1px solid var(--theme-primary-color);
        box-shadow: 0 1px 5px rgba(248, 175, 175, 0.1);
        color: white;
        background: var(--theme-primary-color);
      }
    }
  }
`;

export {OrderScreenHeader, OrderScreenDetails, OrderStatusScreen, NotificationToast}
