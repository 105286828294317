import {MenuItemVariationGroupI, MenuItemVariationOptionI} from "../../../menuInterfaces";
import {ProductVariation} from "./ProductVariation";
import {ProductVariationSingle} from "./ProductVariationSingle";
import {useEffect, useState} from "react";
import {CartItem} from "../../../../cart/CartItem";

interface ProductVariationGroupInterface {
    group: MenuItemVariationGroupI,
    cartItem: CartItem,
    setCartItem: any
}

export function ProductVariationGroup({group, cartItem, setCartItem}: ProductVariationGroupInterface) {
    const [qtyMsg, setQtyMsg] = useState("");
    const [validationError, setValidationError] = useState("");
    const qtyLimit = group.Limited ?  Number(group.Limited)  : 99;
    const [optionsSelected, setOptionsSelected] = useState<Array<MenuItemVariationOptionI>>([]);


    useEffect(() => {
        setValidationError("");
        if(cartItem.GroupValidationErrors.length > 0){
            const errors = cartItem.GroupValidationErrors.find(vError => vError.group.ItemVariationID === group.ItemVariationID);
            if(errors){
                setValidationError(errors.msg);
            }
        }
        setOptionsSelected(cartItem.findVariationGroupCartOptions(group));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cartItem])

    const isSingleChecked = (option: MenuItemVariationOptionI) => {
        return cartItem.findVariationGroupCartOptions(group).some(o => {
            return o.OptionID === option.OptionID
        })
    }

    const multiItemCount = (option: MenuItemVariationOptionI): number => {
        const options = cartItem.findVariationGroupCartOptions(group);
        return options.filter(o => {
            return o.OptionID === option.OptionID
        }).length
    }

    const toggleOptionSingle = (option: MenuItemVariationOptionI) => {
        if(optionsSelected.filter(opt => opt.OptionID === option.OptionID).length > 0){
            setCartItem(cartItem.toggleVariationSingleToGroup({group, option}));
        }else{
            qtyValidation(optionsSelected.length +1);
            if(optionsSelected.length < qtyLimit) {
                setCartItem(cartItem.toggleVariationSingleToGroup({group, option}));
            }
        }

    }

    const addOption = (option: MenuItemVariationOptionI) => {
        qtyValidation(optionsSelected.length +1);
        if(optionsSelected.length < qtyLimit) {
            setCartItem(cartItem.addVariationQtyToGroup({group, option}))
        }
    }

    const removeOption = (option: MenuItemVariationOptionI) => {
        setCartItem(cartItem.deductVariationQtyToGroup({group, option}));
    }

    const qtyValidation = (qty: number) => {
        if( qty >= qtyLimit) {
            setQtyMsg("Limit of (" + qtyLimit + ") reached")
        }else{
            setQtyMsg("");
        }
    }


    return (
        <div id={"group-container-"+group.ItemVariationID}>
            <hr className="indent mb-8"/>
            <h3 className="mb-1 fore-color">
                {group.Name}
                {group.Limited &&
                    <span className="text-gray-500"> ({Number(group.Limited) - optionsSelected.length})</span>
                }

            </h3>
            <p>{group.Description} {qtyMsg}</p>
            {validationError &&
                <p className="text-red-600">{validationError}</p>
            }
            <div className="mb-6"></div>
            {group.OptionList.map(option => {
                if(option.ShowQuantity === "1"){
                    return <ProductVariation
                        key={option.OptionID}
                        option={option}
                        addOption={addOption}
                        removeOption={removeOption}
                        qty={multiItemCount(option)}
                    />
                }
                return <ProductVariationSingle
                    key={option.OptionID}
                    option={option}
                    addOption={toggleOptionSingle}
                    checked={isSingleChecked(option)}
                />
            })}

        </div>
    )
}
