import styled from "styled-components";
import {MediaQueries} from "../styles/queries";

interface NegativeContainerI {
    negative: string
}

const NegativeContainer = styled.div<NegativeContainerI>`
  min-height: 100px;
  min-width: 600px;
  background: transparent;
  margin: ${props => props.negative} auto 0;
  padding-bottom: 16vh;
  @media screen and ${MediaQueries.portrait} {
    margin: 0;
    padding-bottom: 0;
    order: 1;
    min-width: unset;
    background: white;
  }
`;

export default NegativeContainer;
