import {ShopMapUrlI, ShopOpenStatusI, VendorI} from "./vendorInterfaces";

export interface Vendor extends VendorI {};
export class Vendor {
    constructor(vendor: VendorI) {
        Object.assign(this, vendor)
    }

    shopOpenStatus(): ShopOpenStatusI {
        let status: ShopOpenStatusI = {
            Color: 'green',
            Description: 'Open'
        }
        if(this.OpenStatus?.ClosingSoon){
            status.Color = 'orange';
            status.Description = 'Closing Soon'
        }
        if(!this.OpenStatus?.Status){
            status.Color = 'red';
            status.Description = 'Closed'
        }
        return status;
    }

    getShopMapUrl(): ShopMapUrlI {
        let result: ShopMapUrlI = {
            isSet: false,
            url: ""
        };
        if(this.GoogleLatitudeCoord && this.GoogleLatitudeCoord.length){
            result.isSet= true;
            result.url = `https://www.google.com/maps/place/${this.GoogleLatitudeCoord}${this.GoogleLongitudeCoord}`;

        }
        return result;
    }
}
