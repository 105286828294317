import * as StyledTheme from "../../../theme/styled-components/Theme.styled";
import React, {useRef, useState} from "react";
import {Footer} from "../../../theme/copmponents/Footer";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import * as styled from "./AddAdress.styled";
import {AddAddressStep1} from "./AddAddressStep1";
import {AddAddressStep2} from "./AddAddressStep2";
import {AddAddressStep3} from "./AddAddressStep3";
import {PostalAddressI} from "../../userInterfaces";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronLeft} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../intl/translator";

const AddAddress = () => {
    const strings = {
        's_previous_step': Translate('previous_step')
    }
    const [step, setStep] = useState(2);
    const postInput = useRef(null);
    const [postCode, setPostCode] = useState("");
    const [address, setAddress] = useState<PostalAddressI>();
    const navigate = useNavigate();

    const onPreviousClick = () => {
        if([1,2].includes(step)){
            navigate('/address/list')
        }else{
            setStep(step-1);
            setPostCode('');
        }
    }

    return (
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage>
                <div className="actions indent  py-8">
                    <div className="flex items-center">
                        <div className="flex items-center cursor-pointer" onClick={onPreviousClick}>
                            <ActionButton>
                                <BsChevronLeft size={24}/>
                            </ActionButton>
                            <div className="ml-15">{strings.s_previous_step}</div>
                        </div>

                    </div>
                </div>
            </StyledTheme.SizedContentPage>
            <Footer negative="-50vh">
                <>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card}/>
                    </div>
                    <styled.AddAddress className="pt-36 px-8 pb-12 md:px-6 card-panel">
                        {step === 1 &&
                            <AddAddressStep1 setStep={setStep} />
                        }
                        {step === 2 &&
                            <AddAddressStep2
                                setStep={setStep}
                                setPostCode={setPostCode}
                                setAddress={setAddress}
                                postCodeFieldRef={postInput}
                                postCode={postCode}
                            />
                        }
                        {step === 3 && address &&
                            <AddAddressStep3 setStep={setStep} address={address}/>
                        }
                    </styled.AddAddress>
                </>
            </Footer>


        </div>
    );
};

export default AddAddress;
