import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectOpenSidebar, setOpenSidebar, SideBarOptions} from "../../../core/panelsSlice";
import {SideBar} from "../../../core/components/SideBar/SideBar";
import {selectCart} from "../../cartSlice";
import {useEffect, useState} from "react";
import {Cart} from "../../Cart";
import {CartComments} from "./components/CartComments";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {IoIosClose} from "react-icons/io";
import {CartOrderDetails} from "./components/CartOrderDetails";
import {CartOrderItems} from "./components/CartOrderItems";
import {CartCosts} from "./components/CartCosts";
import {CartCheckoutButton} from "./components/CartCheckoutButton";


export function CartDrawer() {

    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const openSidebar = useAppSelector(selectOpenSidebar);
    const cartSliceState = useAppSelector(selectCart);
    const [cart, setCart] = useState<Cart>(new Cart({items: []}));
    const dispatch = useAppDispatch();

    useEffect(() => {
        setCart(new Cart(cartSliceState));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const handleFocus = () => setIsKeyboardVisible(true);
        const handleBlur = () => setIsKeyboardVisible(false);

        const inputs = document.querySelectorAll('input, textarea');

        inputs.forEach(input => {
            input.addEventListener('focus', handleFocus);
            input.addEventListener('blur', handleBlur);
        });

        return () => {
            inputs.forEach(input => {
                input.removeEventListener('focus', handleFocus);
                input.removeEventListener('blur', handleBlur);
            });
        };
    }, [cartSliceState])


    const bottomPadding = isKeyboardVisible ? '20px' : '0px';
    const close = () => {
        dispatch(setOpenSidebar(SideBarOptions.CLOSED,));
    }

    return (
        <SideBar open={openSidebar === SideBarOptions.CART}>
            <div className="h-full overflow-y-scroll overscroll-contain" style={{ paddingBottom: '100px' }}>
                <div className="indent flex items-center py-10">
                    <ActionButton
                        onClick={close}
                        style={{position: 'absolute', left: '10px'}}
                    >
                        <IoIosClose size="32"/>
                    </ActionButton>

                </div>
                <CartOrderDetails/>
                <CartOrderItems cart={cart}/>
                <CartCosts cart={cart}/>
                {cart.Items.length > 0 && <CartComments/>}
                <CartCheckoutButton cart={cart}/>
            </div>
        </SideBar>
    )
}
