// import {ProductVariationPrice, ProductVariationQuantity} from "../../../../../styled_components/Product";
import {MdCheck} from "react-icons/md";
// import {TextP} from "../../../../../styled_components/General";
import {MenuItemVariationOptionI} from "../../../menuInterfaces";
import {useAppSelector} from "../../../../../app/hooks";
import {selectVendor} from "../../../../vendor/vendorSlice";
import {canShowVariationPrices} from "../../../menuService";
import * as styled from '../Product.styled';

interface ProductVariationSingleInterface {
    roundBoxDesign?: boolean,
    option: MenuItemVariationOptionI,
    checked: boolean,
    addOption: any
}

export function ProductVariationSingle({roundBoxDesign, option, addOption, checked}: ProductVariationSingleInterface) {
    const vendor = useAppSelector(selectVendor);
    const showPrices = canShowVariationPrices({
        price: option.price, vendorVisibility: vendor.VisibilityOption
    })

    return (
        <div className="flex justify-between items-center mb-4 cursor-pointer" onClick={() => addOption(option)}>
            <div className="flex items-center" style={{height: '30px'}}>
                <styled.ProductVariationQuantity populated={checked} round={roundBoxDesign}>
                    <span>
                       <MdCheck size={18}/>
                    </span>
                </styled.ProductVariationQuantity>

                <p className="fore-color">{option.Name}</p>
            </div>
            {
                showPrices &&
                <styled.ProductVariationPrice active={checked}>
                    &euro;{Number(option.price).toFixed(2)}
                </styled.ProductVariationPrice>
            }
        </div>
    );
}
