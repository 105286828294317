import * as styled from './AddAdress.styled';
import React, {RefObject, useEffect, useState} from "react";
import {PostalAddressI} from "../../userInterfaces";
import {postalAddresses} from "../../userRepository";
import {AiOutlineLoading3Quarters} from "react-icons/ai";

interface PostCodeResultsI {
    postCode?: string;
    postCodeFieldRef: RefObject<HTMLInputElement>;
    setAddress: any;
    setStep: any;
}

interface PositionCoordsI {
    top: number,
    left: number,
    width: number
}

export function PostCodeResults({postCode, postCodeFieldRef, setAddress, setStep}: PostCodeResultsI) {
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState<PositionCoordsI>({top: 0, left: 0, width: 0})
    const [windowWidth, setWindowWidth] = useState(0);
    const [addresses, setAddresses] = useState<Array<PostalAddressI>>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const rect = postCodeFieldRef.current?.getBoundingClientRect();
        if (rect) {
            setPosition({
                top: rect.bottom,
                left: rect.left,
                width: rect.width
            })
        }
    }, [postCodeFieldRef, windowWidth])

    useEffect(() => {
        if (postCode && postCode?.length > 3) {
            setVisible(true)
            setLoading(true);
            postalAddresses(postCode)
                .then(res => {
                    setAddresses(res);
                    setLoading(false);
                })
                .catch(e => {
                    setLoading(false);
                })
        } else {
            setVisible(false)
            setLoading(false);
        }
    }, [postCode])

    useEffect(() => {
        function handleResize() {
            setWindowWidth(Math.floor(Math.random() * 5000));
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener("resize", handleResize);
    }, [])


    const onAddressSelect = (address: PostalAddressI) => {
        setAddress(address);
        setAddresses([]);
        setStep(3);
    }

    return (
        <styled.PostCodeResults
            top={position.top}
            left={position.left}
            rectWidth={position.width}
            visible={visible}
            className={'card-panel'}
        >
            <div className="wrapper p-3">
                {loading &&
                    <div className="flex justify-center items-center pt-16">
                        <AiOutlineLoading3Quarters size={50}
                                                   className="animate-spin m-auto"
                                                   color="var(--theme-primary-color)"/>
                    </div>
                }
                {!loading && addresses.length > 0 &&
                    <div className="address-list">
                        {addresses.map(address => {
                            return <div className='address-list-item' key={'post-'+address.ID}
                            onClick={() => {onAddressSelect(address)}}
                            >
                                {address.RoadNameEn}
                            </div>
                        })}
                    </div>
                }

            </div>

        </styled.PostCodeResults>

    )
}
