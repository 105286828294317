import styled from "styled-components";

const Collapsible = styled.div`
  overflow: hidden;
  box-shadow: var(--theme-shadow);
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  max-height: 35px;
  border-radius: 5px;
  padding: 5px;
  &.expanded {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
`;

const CollapsibleHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
  .icon {
    width: 25px
  }
`;

export {
    CollapsibleHead,
    Collapsible
}
