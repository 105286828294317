import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {useNavigate} from "react-router-dom";
import {selectUser, setUserVerificationStatus} from "../../userSlice";
import {useState} from "react";
import {userSendOtp, userUpdateData,userVerifyOtp} from "../../userRepository";
import {Translate} from "../../../intl/translator";
import {setUserOTPValidationStatus} from "../../userService";
import OtpInput from "react18-input-otp";

interface OtpScreenI {
    open: boolean,
    setOpen: any,
    otpTimeout: number,
    setOtpTimeout: any
}

export function OtpForm({open, setOpen, otpTimeout, setOtpTimeout}: OtpScreenI) {
    const strings = {
        s_title: Translate('otp_screen_title'),
        s_label: Translate('otp_field_label'),
        s_no_code: Translate('otp_field_description'),
        s_resend: Translate('resend')

    }

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);
    const [error, setError] = useState(false);
    const [otpValue, setOtpValue] = useState("");

    const resendOtp = () => {
        if (otpTimeout === 0 && user.PhoneNum && user.PhoneCode) {
            userSendOtp(user.PhoneNum, user.PhoneCode).then(() => {
                setOtpTimeout(60);
            })
        }
    }

    const handleOtp = (value: string) => {
        setOtpValue(value);
        if (value.length < 6) {
            return;
        }
        if (user.PhoneNum) {
            userUpdateData(user);

            userVerifyOtp(user.PhoneNum, value)
                .then((user1) => {
                    setUserOTPValidationStatus(user.PhoneNum?.toString() ?? 'invalid');
                    dispatch(setUserVerificationStatus("1"));
                    // userUpdateData(user).then(() => {
                       
                        setOtpValue("");
                        let intent = localStorage.getItem('intent');
                        if (intent) {
                           
                            localStorage.removeItem(intent);
                            navigate(intent);
                        } else {
                            navigate('/delivery/type');
                        }
                    // })
                })
                .catch(e => {
                    setError(true);
                    setOtpValue("");
                })
        }
    }

    return (
        <>
            {open &&
                <div className="text-center mt-8 flex flex-col items-center">
                    <p className="text-lg">{strings.s_title}</p>
                    <p className="primary-color text-2xl mb-3">{user.PhoneNum}</p>
                    <OtpInput
                        autoComplete={'true'}
                        value={otpValue}
                        numInputs={6}
                        onChange={handleOtp}
                        isInputNum={true}
                        containerStyle={'pincode-input-container'}
                        inputStyle={'input-style'}
                        shouldAutoFocus={true}
                    />
                    {error &&
                        <p className="text-red-600">Invalid Code</p>
                    }
                    <div className="mt-10">
                        {strings.s_no_code}
                        <br/>
                        <p className={`pt-2 text-lg cursor-pointer ${otpTimeout === 0 ? 'primary-color' : 'text-gray-700'}`}
                           onClick={resendOtp}>
                            {strings.s_resend} OTP {otpTimeout > 0 && '('+otpTimeout+')'}
                        </p>

                    </div>
                </div>
            }
        </>
    )
}
