import {VendorPaymentMethodI} from "../../../vendor/vendorInterfaces";
import * as styled from "./Checkout.styled";
import {BsChevronRight} from "react-icons/bs";
import {getPaymentIconById} from "../../cartService";

type Props = {
    method: VendorPaymentMethodI,
    action: any
}

export function CheckoutMethod(params: Props) {

    const {method, action} = params;

    const creditCardMethods = ['2', '4', '8','9','10','28'];

    return (
        <styled.CheckoutMethodItem onClick={() => {action(method)}}>
            <div className="payment-logo flex justify-center items-center">
                <img src={getPaymentIconById(method.PaymentTypeID)} alt=""/>
            </div>
            <div className="payment-divider"></div>
            <div className="payment-description">
                {!creditCardMethods.includes(method.PaymentTypeID) &&
                    <>{method.PaymentTypeName}</>
                }
                {creditCardMethods.includes(method.PaymentTypeID) &&
                    <>
                        Pay Now Online
                        <small>Credit Card</small>
                    </>

                }
            </div>
            <div className="payment-arrow">
                <BsChevronRight size="22" color="#ccc"/>
            </div>
        </styled.CheckoutMethodItem>
    )
}
