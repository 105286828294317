import * as styled from './Collapsible.styled'
import React, {useEffect, useState} from "react";
import {HiChevronDown} from "react-icons/hi";

interface CollapsibleI {
    children: JSX.Element,
    title?: string,
    open?: boolean
}
export function Collapsible({children, title, open}:CollapsibleI) {
    const [expanded, setExpanded] = useState(open);

    const collapseToggle = () => {
        setExpanded(!expanded);
    }

    useEffect(() => {
        setExpanded(open)
    },[open])

    return (
        <div>

            <styled.Collapsible className={expanded ? "expanded" : ""}>
                <styled.CollapsibleHead onClick={collapseToggle}>
                    <h3 className="text-xl">
                        {title ?? ""}
                    </h3>
                    <div className="icon"  onClick={collapseToggle}>
                        <HiChevronDown color="var(--theme-modded-fore-color)"
                                       size={25} className={`transition-all ${expanded ? "rotate-180" : ""}`}/>
                    </div>
                </styled.CollapsibleHead>
                {children}
            </styled.Collapsible>
        </div>
    )
}
