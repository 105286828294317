import styled from "styled-components";

const Title = styled.h3`
  font-size: 24px;
  color: var(--theme-modded-fore-color);
  margin-left: var(--theme-walls-indentation);
  margin-right: var(--theme-walls-indentation);
  border-bottom: thin solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-weight: 500;
`
export {Title}
