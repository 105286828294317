import styled, {css} from "styled-components";
interface TextProps {
    weight?: string;
    color?: string;
}

export const TextP = styled.p<TextProps>`
  font-size: var(--theme-fz-text);
  line-height: 18px;
  color: ${props => props.color ? props.color : `var(--theme-grey-color)`};
  font-weight: ${props => props.weight ? props.weight : `normal`};
`;

export const Title1 = styled.h1<TextProps>`
  font-size: var(--theme-fz-title-1);
  font-weight: 500;
  color: ${props => props.color ? props.color : `var(--theme-modded-fore-color)`};
`;

export const Title2 = styled.h2<TextProps>`
  font-size: var(--theme-fz-title-2);
  font-weight: 500;
  color: ${props => props.color ? props.color : `var(--theme-modded-fore-color)`};
`;

export const Collapsible = styled.div`
  overflow: hidden;
  box-shadow: var(--theme-shadow);
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  max-height: 0;
  border-radius: 5px;

  &.expanded {
    max-height: 10000px;
    transition: max-height 1s ease-in-out;
  }

  &.no-shadow{
    box-shadow: none;
  }
`;
