import {MenuItemVariationGroupI, MenuItemVariationOptionI} from "../../../menuInterfaces";
import {ProductVariationSingle} from "./ProductVariationSingle";
import {CartItem} from "../../../../cart/CartItem";
import {useEffect, useState} from "react";

interface ProductVariationGroupInterface {
    group: MenuItemVariationGroupI,
    cartItem: CartItem,
    setCartItem: any
}


export function ProductVariationGroupRadio({group, cartItem, setCartItem}: ProductVariationGroupInterface) {

    const [validationError, setValidationError] = useState("");
    useEffect(() => {
        setValidationError("");
        if(cartItem.GroupValidationErrors.length > 0){
            const errors = cartItem.GroupValidationErrors.find(vError => vError.group.ItemVariationID === group.ItemVariationID);
            if(errors){
                setValidationError(errors.msg);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cartItem])

    const addOption = (option: MenuItemVariationOptionI) => {
        setCartItem(cartItem.selectRadioVariationOfGroup({group, option}))
    }

    const isChecked = (option: MenuItemVariationOptionI) : boolean => {
        return cartItem.findVariationGroupCartOptions(group).some(o => {
            return o.OptionID === option.OptionID
        })
    }

    return (
        <div id={"group-container-"+group.ItemVariationID}>
            <hr className="indent mb-8"/>
            <h3 className="mb-1 fore-color">{group.Name}</h3>
            <p>{group.Description}</p>
            {validationError &&
                <p className="text-red-600">{validationError}</p>
            }

            <div className="mb-6"></div>
            {group.OptionList.map(option => {
                return <ProductVariationSingle roundBoxDesign key={option.OptionID} option={option} addOption={addOption} checked={isChecked(option)}/>
            })}
        </div>
    );
}
