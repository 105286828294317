import * as styled from './CategoriesBar.styled'
import {useState} from "react";
import {Categories} from "../Categories/Categories";
import {ActionButton} from "../../../../theme/styled-components/UI.styled";
import {HiMenuAlt2} from "react-icons/hi";
import {useAppDispatch} from "../../../../../app/hooks";
import {setOpenSidebar, SideBarOptions} from "../../../../core/panelsSlice";
import {Subcategories} from "../Subcategories/Subcategories";

export function CategoriesBar() {
    const [activeCategory, setActiveCategory] = useState("-");
    // const [show, setShow] = useState(true);
    const dispatch = useAppDispatch();

    return (
        <styled.CategoriesBar>
            <div className="categories">
                <ActionButton className="ml-2" style={{minWidth: "38px"}} onClick={
                    () => dispatch(setOpenSidebar(SideBarOptions.CATEGORIES, ))
                }>
                    <HiMenuAlt2 />
                </ActionButton>
                <Categories stickyShown={true} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
            </div>
            <div className="subcategories">
                <Subcategories stickyShown={true} activeMainCategory={activeCategory} />
            </div>

        </styled.CategoriesBar>
    )
}
