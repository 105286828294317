import {useAppSelector} from "../../app/hooks";
import {selectLocale} from "./intlSlice";
import {translations} from "./translations";


export function Translate (string = ""){
    const locale = useAppSelector(selectLocale);
    if(locale.ShortName in translations) {
        if(string in translations[locale.ShortName]) {
            return translations[locale.ShortName][string];
        }else if(string in translations.En) {
            return translations.En[string];
        }
    }
    return string
}
