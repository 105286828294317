import styled from "styled-components";

const CheckoutMethodItem = styled.div`
  background: var(--theme-modded-card-bg);
  box-shadow: var(--theme-shadow);
  min-height: 70px;
  display: flex;
  padding: 15px;
  align-items: stretch;
  margin-top: 20px;
  cursor: pointer;
  .payment-logo{
    max-width: 50px;
  }
  .payment-divider{
    border-left: solid thin var(--theme-modded-fore-color);
    margin: 0 15px;
  }
  .payment-description{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-weight: 400;
    font-size: 18px;
    color: var(--theme-modded-fore-color);
    small{
      font-size: 14px;
      margin-top: 5px;
      color: gray;
    }
  }
  .payment-arrow{
    width: 22px;
    display: flex;
    align-items: center;
  }
`;

const CheckoutDetails = styled.div`
  margin-top: 15px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  >div{
    max-width: 300px;
  }
  @media (max-width: 860px){
    grid-template-columns: 1fr;
  }
`

export {
    CheckoutMethodItem, CheckoutDetails
}
