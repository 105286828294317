import {useAppSelector} from "../../../../app/hooks";
import {selectCart} from "../../cartSlice";
import {useEffect, useState} from "react";
import {Cart} from "../../Cart";
import * as styled from "./CartScreen.styled";
import {CartComments} from "./components/CartComments";
import {CartOrderDetails} from "./components/CartOrderDetails";
import {CartOrderItems} from "./components/CartOrderItems";
import {CartCosts} from "./components/CartCosts";
import {CartCheckoutButton} from "./components/CartCheckoutButton";

export function CartSidebar() {

    const cartSliceState = useAppSelector(selectCart);
    const [cart, setCart] = useState<Cart>(new Cart({items: []}));

    useEffect(() => {
        setCart(new Cart({...cartSliceState}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartSliceState])

    return (
        <styled.CartSidebar>
            <CartOrderDetails />
            <CartOrderItems cart={cart} />
            <CartCosts cart={cart} />
            {cart.Items.length > 0 && <CartComments/>}
            <CartCheckoutButton cart={cart} />
        </styled.CartSidebar>
    )
}
