import * as styled from './Lightbox.styled'
import {useAppDispatch} from "../../../../app/hooks";
import {LightboxOptions, setOpenLightbox} from "../../panelsSlice";

type Props = {
    children: JSX.Element,
    open: boolean,
    close?: any
}

export function Lightbox({children, open, close} :Props) {
    const dispatch = useAppDispatch();

    const closeLightbox = () => {
        dispatch(setOpenLightbox(LightboxOptions.CLOSED))
    }

    return (
        <styled.Lightbox open={open}>
            <div className="backdrop" onClick={close ?? closeLightbox}></div>
            <div className="inner">{children}</div>
        </styled.Lightbox>
    )
}
