import React from "react";
import {MenuCategoryI} from "../../../menuInterfaces";
import {MenuSubcategory} from "../MenuSubcategory/MenuSubcategory";
import { v4 as uuid } from 'uuid';
import {Element} from "react-scroll";
import * as styled from './MenuCategory.styled';

export function MenuCategory(category: MenuCategoryI) {
    return (
        <Element name={'category-' + category.Id}>
            <styled.Title key={uuid()}>{category.Name}</styled.Title>
            <div
                id={'category-' + category.Id}
                className="category-sticky-identifier"
            >
                {category.Subcategories.map((subcategory) => {
                    if(subcategory.Name === 'None' && subcategory.Items.length && !subcategory.filtered){
                        return <MenuSubcategory
                            key={uuid()}
                            Name={subcategory.Name}
                            Id={subcategory.Id}
                            Items={subcategory.Items}
                            categoryId={category.Id}
                        />;
                    }return null;
                })}
                {category.Subcategories.map((subcategory, i) => {
                    if(subcategory.Name !== 'None' && subcategory.Items.length && !subcategory.filtered){
                        return <div key={uuid()}>
                            {i === 0 && <div key={uuid()} className="mt-7"></div>}
                            <MenuSubcategory
                                key={uuid()}
                                Name={subcategory.Name}
                                Id={subcategory.Id}
                                Items={subcategory.Items}
                                categoryId={category.Id}
                            />
                        </div>;
                    }return null;
                })}
            </div>
        </Element>
    )
}
