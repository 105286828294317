import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getVendorStatus} from "../vendorRepository";
import {useEffect} from "react";
import {selectVendor, setVendorOpenStatus} from "../vendorSlice";
import {FaDotCircle} from "react-icons/fa";

export function VendorOpenStatusPill(){
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch()



    useEffect(() => {
        setTimeout(() => {
            getVendorStatus(vendor.OutletID).then(res => {
                dispatch(setVendorOpenStatus(res))
            })
        }, 500)


        const refreshStatus = setInterval(()=> {
            getVendorStatus(vendor.OutletID).then(res => {
                dispatch(setVendorOpenStatus(res))
            })
        }, 120000);

        return () => {
            clearInterval(refreshStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <p className="mb-3">
            <FaDotCircle  color={vendor.shopOpenStatus().Color} className="inline-block mr-3"/>
            {vendor.shopOpenStatus().Description}
        </p>
    )
}
