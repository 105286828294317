import {Translate} from "../../../intl/translator";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectLastOrder} from "../../ordersSlice";
import React, {useEffect, useState} from "react";

import {selectCart, setCart} from "../../cartSlice";
import {useNavigate} from "react-router-dom";
import {AiOutlineLoading3Quarters} from "react-icons/ai";

import styled from "styled-components";
interface AppButtonI{
    disable?: boolean
}
export const OrderScreenHeader = styled.div`
  //background: var(--theme-primary-color);
  background: ${props => props.color ? props.color : "var(--theme-primary-color)"};
  min-height: 300px;
  width: 50%;
  border-radius: 0 0 50% 50%;
  margin: -175px auto 30px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 50px;
  flex-direction: column;
  color: white;
  font-size: 20px;

 @media (max-width: 768px) {
    width: 90%;
  }
  
`;
export const AppButton = styled.div<AppButtonI>`
  background-color: ${props => props.disable ? 'grey' : "var(--theme-primary-color)"};
  height: 54px;
  margin-bottom: 15px;
  border-radius: 27px;
  width: 50%;
  margin: auto;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
export function OrderPaymentFailed() {
    const strings = {
        s_order: Translate('order'),
        s_order_faild: Translate('order_failed'),
        s_order_again: Translate('order_again')
    };

    const lastOrder = useAppSelector(selectLastOrder);
    const cart = useAppSelector(selectCart);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1250)
    })

    const handleClick = () => {
        // alert('Blue button clicked!');
        navigate('/delivery/type')
      };
    return (
        <div className="flex flex-col h-screen">
            {!loading &&
                <>
                    <OrderScreenHeader color={'red'}>
                        <h2 className="text-center">{strings.s_order_faild} <br/>
                         
                        </h2>

                    </OrderScreenHeader>
                    <div className="grow indent pt-8">
                        <p className="text-lg text-center">{strings.s_order_faild}</p>
                        <AppButton onClick={handleClick}>{strings.s_order_again}</AppButton>
                    </div>
                </>
            }
            {loading &&
                <div className="grow indent pt-8">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin m-auto" color="var(--theme-primary-color)"/>
                </div>
            }
            {/* <AppCredits /> */}
        </div>
    )
}
