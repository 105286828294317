import {AppCreditsContainer, FooterLogo, NegativeContainer} from "../styled-components/Theme.styled";
import * as StyledTheme from "../styled-components/Theme.styled";

type Props = {
    children: JSX.Element,
    negative?: string
}
export function Footer({children, negative} :Props) {
    return (
        <StyledTheme.FooterWrapper>
            <FooterLogo>
                <img className={"splash-logo"} src="/images/branding/logo.svg" alt=""/>
            </FooterLogo>
            <NegativeContainer negative={negative ?? "-50vh"}>
                {children}
            </NegativeContainer>
            <AppCreditsContainer>
                <a target="_blank" href="https://ninatech.co" className="text-xl" rel="noreferrer">
                    Delivered by <span>nina</span>
                </a>
          
            </AppCreditsContainer>
        </StyledTheme.FooterWrapper>
    );
}
