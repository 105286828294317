import {Dialog, Transition} from '@headlessui/react'
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {LightboxOptions, selectOpenLightbox, setOpenLightbox} from "../../../../core/panelsSlice";
import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {selectCart} from "../../../cartSlice";
import {Cart} from "../../../Cart";

export function CartMinOrderChargeDialog() {
    const openLightbox = useAppSelector(selectOpenLightbox);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const cart = useAppSelector(selectCart)
    const [fee, setFee] = useState(0);
    let [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(openLightbox === LightboxOptions.CONFIRM_MIN_CHARGE)
    }, [openLightbox])

    useEffect(() => {
        let cartM = new Cart(cart);
        setFee(cartM.calcDeliveryMinOrderFee());
    }, [cart])

    const close = () => {
        dispatch(setOpenLightbox(LightboxOptions.CLOSED));
        setTimeout(() => {
            document.documentElement.style.overflow = "auto";
        }, 100)
    }

    const accept = () => {
        close();
        navigate('/checkout')
    }

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog open={isOpen} onClose={close} className="relative z-50">

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30"/>
                </Transition.Child>

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-sm rounded bg-white px-8 py-4">
                            <Dialog.Title className={'text-xl font-medium mb-2'}>Minimum Order</Dialog.Title>

                            <p>
                                An amount of &euro;{fee.toFixed(2).toString()} is needed for the minimum order amount required.
                            </p>

                            <div className={'flex mt-4'}>
                                <button
                                    onClick={close}
                                    className="bg-transparent hover:bg-green-700 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-700 hover:border-transparent rounded">
                                    Add more items
                                </button>
                                <button
                                    onClick={accept}
                                    className="bg-transparent hover:bg-green-700 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-700 hover:border-transparent rounded ml-4">
                                    Accept & Pay
                                </button>

                            </div>
                        </Dialog.Panel></Transition.Child>
                </div>

            </Dialog>
        </Transition>
    )
}
