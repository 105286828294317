import {FormButton} from "../../../theme/styled-components/Form.styled";
import React from "react";

interface Props {
    setStep: any
}

export function AddAddressStep1({setStep}:Props) {
    return (
        <div className='step-1'>
            <h1 className='text-center'>Let’s add your <br/>address!</h1>
            <p className='text-center'>You don’t have any saved addresses. <br/>Please start by adding one.</p>
            <FormButton className="m-auto" width={300} background="var(--theme-sky-blue)"
                        onClick={() => setStep(2)}>
                Add new address
            </FormButton>
        </div>
    )
}
