import {configureStore, ThunkAction, Action, combineReducers} from '@reduxjs/toolkit';
import {loadState} from "./browser-storage";
import themeReducer from '../features/theme/themeSlice';
import userReducer from "../features/user/userSlice"
import intlReducer from '../features/intl/intlSlice';
import vendorReducer from '../features/vendor/vendorSlice';
import menuReducer from '../features/menu/menuSlice';
import ordersReducer from '../features/cart/ordersSlice';
import panelsReducer from '../features/core/panelsSlice';
import cartReducer from '../features/cart/cartSlice';
const rootReducer = combineReducers({
    theme: themeReducer,
    user: userReducer,
    intl: intlReducer,
    vendor: vendorReducer,
    menu: menuReducer,
    purchases: ordersReducer,
    panels: panelsReducer,
    cart: cartReducer,
})


export const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadState(),
    middleware: (getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })),
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
