import React, {useEffect, useState} from 'react';
import {
    getTableDetails,
    getVendor,
    getVendorButtons, getVendorPaymentMethods,
    getVendorSchedule
} from "../../vendor/vendorRepository";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectVendor,
    setTableInfo,
    setVendor,
    setVendorButtons, setVendorFavourites, setVendorPaymentMethods,
    setVendorSchedule,
    unsetVendor
} from "../../vendor/vendorSlice";
import {selectUser, setUserCompletedOrders} from "../../user/userSlice";
import {selectLocale, setAvailableLocales} from "../../intl/intlSlice";
import {setFavourites, setItemSelected, setMenu, setMenuList} from "../../menu/menuSlice";
import {VendorLoadDataI, VendorOperation} from "../../vendor/vendorInterfaces";
import {setVendorColor} from "../../theme/themeSlice";
import CONFIG from "../../../app/config";
import {
    prepareUserLastCart,
    userCompletedOrders,
    userFavouriteOutlets,
    userFavouriteProducts
} from "../../user/userRepository";
import {getMenu} from "../../menu/menuRepository";
import {getAvailableLocales} from "../../intl/intlRepository";
import {UserStatus} from "../../user/userInterfaces";
import {addOrderToPastOrders} from "../../cart/ordersSlice";
import {lastOrder} from "../../cart/cartRepository";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import { Transition } from '@headlessui/react'
import '../styles/splash-screen.scss'
import Cookies from "universal-cookie";

export function Splash() {
    const dispatch = useAppDispatch();
    const [preloaded, setPreloaded ] = useState(false);
    const [loaded, setLoaded ] = useState(false);
    const [error, setError] = useState(false);
    const locale = useAppSelector(selectLocale);
    const vendor = useAppSelector(selectVendor);
    const user = useAppSelector(selectUser);
    const cookies = new Cookies();
    const navigate = useNavigate();

    const doResets = () => {
        dispatch(setItemSelected(null));
        dispatch(unsetVendor);
        localStorage.removeItem('load');
    }

    useEffect(() => {
        // doResets();
        const loadString = localStorage.getItem('load');
        if (loadString) {
            cookies.set('refid', '', { path: '/', domain: '.ninatech.co' });
            cookies.remove('refid', { path: '/', domain: '.ninatech.co' });
            
            initApp(loadString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(loaded && !error){
            // if(user && user.RegisteredUserID !== CONFIG.GUEST_USER_ID && user.Verified === "1"){
            //     navigate('/delivery/type')
            // }else{
            //     navigate('/login')
            // }
            navigate('/delivery/type')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user, loaded, error])

    const initApp = (loadString: string) => {
        const loadData : VendorLoadDataI = JSON.parse(loadString);
        doResets();
        preloadApp(loadData).then(loadData => {
            loadApp(loadData).then(res => {
            }).catch(e => setError(true));
        }).catch(e => setError(true));
    }

    const preloadApp = (loadData: VendorLoadDataI) => {
        return new Promise<VendorLoadDataI>((resolve, reject) => {
            Promise.all([
                getVendor(loadData.id),
            ]).then((res) => {
                dispatch(setVendor(res[0]))
                dispatch(setVendorColor(res[0].Color ?? 'E84D39'))
                setPreloaded(true);
                //redirect if shop is disabled
                if(
                    res[0].OperationID === VendorOperation.Disable
                ){
                    document.location.replace("/");
                }
                setTimeout(() => {
                    resolve(loadData);
                }, 10)

            }).catch(e => reject(e));
        })
    }

    const loadApp = (loadData: VendorLoadDataI) => {
        return new Promise((resolve, reject) => {
            const start = performance.now();
            if(CONFIG.ENABLE_FOREGROUND_NOTIFICATIONS_USER_IDS.includes(user.RegisteredUserID)
                && CONFIG.ENABLE_FOREGROUND_NOTIFICATIONS){
                // getFirebaseToken().then(token => {
                //     dispatch(setUserFirebaseToken(token))
                // }).catch(e => {})
            }
            Promise.all([
                getMenu(loadData.id, locale.LanguageID,user.RegisteredUserID),
                getVendorButtons(loadData.id),
                getAvailableLocales(),
                getTableDetails(loadData.table),
                getVendorSchedule(loadData.id),
                userFavouriteProducts(user.RegisteredUserID, loadData.id),
                userFavouriteOutlets(user.RegisteredUserID),
                userCompletedOrders(user.RegisteredUserID, loadData.id),
                getVendorPaymentMethods(loadData.id),
                lastOrder(user.RegisteredUserID, loadData.id),
            ]).then(res => {
                dispatch(setMenu(res[0].catalog));
                dispatch(setMenuList(res[0].list));
                dispatch(setVendorButtons(res[1]));
                dispatch(setAvailableLocales(res[2]));
                dispatch(setTableInfo(res[3]));
                dispatch(setVendorSchedule(res[4]));
                dispatch(setFavourites(res[5]));
                dispatch(setVendorFavourites(res[6]));
                dispatch(setUserCompletedOrders(res[7].length));
                dispatch(setVendorPaymentMethods(res[8]));
                if(res[9] && user.Verified === UserStatus.Verified){
                    dispatch(addOrderToPastOrders(prepareUserLastCart(res[9], res[0].list)))
                }
                setTimeout(() => {
                    setLoaded(true);
                }, getDurationRemaining(performance.now() - start))
            }).catch(e => reject(e));
        })

    }

    return (
        <>
            {!preloaded && !error &&
                <div className="flex items-center justify-center h-screen">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                </div>
            }
            <Transition
                show={preloaded && !loaded && !error}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={"splash-screen"}>
                    {vendor.SplashImage &&
                        <img className={"splash-logo"} src={CONFIG.SPLASH_IMAGES_URL + vendor.SplashImage} alt=""/>
                    }
                    {!vendor.SplashImage &&
                        <img className={"splash-logo"} src={CONFIG.LOGOS_URL + vendor.FileName} alt=""/>
                    }
                </div>
            </Transition>
            {error &&
                <div className="flex items-center justify-center h-screen flex-col">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                    <br/>
                    <div>error loading...</div>
                </div>

            }
        </>
    )
}

const getDurationRemaining = (elapsed: number) => {
    const loadDelay = 2500;
    if(elapsed >= loadDelay) {
        return 1;
    }else{
        return loadDelay - elapsed;
    }
}
