import styled from "styled-components";

const UserAddresses = styled.div`
  padding: 0 100px 30px;
  overflow: hidden;

  h1 {
    font-size: 30px;
    margin-top: 50px;
  }

  .header-bar {
    display: grid;
    grid-template-columns: 3fr 6fr 3fr;
    grid-template-areas: "actions logo right";
    align-items: center;
    min-height: 185px;

    .actions {
      grid-area: actions;
    }

    .logo {
      grid-area: logo;
      align-items: center;
      justify-self: center;
      align-self: center;
    }
  }

  .results {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
  }
  @media(max-width: 960px){
    padding: 0 35px 30px;
    .back-btn-label{
      display: none;
    }
  }
  @media(max-width: 681px){
    h1{
      text-align: center;
      margin-top: 45px;
    }
    
    .results{
      & >div{
        margin: auto;
      }
    }
    
  }
`

const AddressCard = styled.div`
  width: 300px;
  min-height: 300px;
  border-radius: 15px;
  padding: 15px;
  box-shadow: var(--theme-shadow);
  .header{
    height: 50px;
    box-shadow: 0 2px rgba(204,204,204,0.4);
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    .actions{
      align-self: start;
      justify-self: start;
    }
    .status{
      align-self: center;
      justify-self: center;
    }
    .comments{
      align-self: start;
      justify-self: end;
    }
  }
  .content{
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 45px);
    p{
      margin-top: 10px;
      font-size: 18px;
    }
  }
`;

const AddAddressBox = styled.div`
  width: 300px;
  min-height: 300px;
  border-radius: 15px;
  padding: 15px;
  border: thin #ccc dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .plus-wrapper{
      width: 75px;
      border: solid thin #ccc;
      aspect-ratio: 1;
      margin-bottom: 15px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &:hover{
    border: solid thin var(--theme-sky-blue);
    cursor: pointer;
    box-shadow: var(--theme-shadow);
    transition: all 200ms;
    .plus-wrapper{
      border:dashed thin var(--theme-sky-blue);
    }
  }
`;

export {UserAddresses, AddressCard, AddAddressBox}
