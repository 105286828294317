import {CustomSwitchContainer, CustomSwitchHandle} from "../../../theme/styled-components/UI.styled";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {useState} from "react";
import {selectMode, setSpecifiedMode} from "../../../theme/themeSlice";
import {BsMoon, BsSun} from "react-icons/bs";

export function DarkModeSwitch() {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(selectMode);
    const [status, setStatus] = useState(mode === 'light');

    const handleChange = () => {
        const newStatus = !status;
        setStatus(newStatus);
        dispatch(setSpecifiedMode(newStatus ? 'light' : 'dark'))
    }

    return (
        <CustomSwitchContainer
            id="dark-mode-switch"
            onClick={handleChange}
            switchOnColor="var(--theme-primary-color)"
            status={status}
            switchOffColor="#333"
        >
            <div>Light Mode</div>
            <CustomSwitchHandle >
                {status && <BsSun />}
                {!status && <BsMoon />}
            </CustomSwitchHandle>
            <div>Dark Mode</div>
        </CustomSwitchContainer>
    )
}
