import {CartType} from "../../../cartInterfaces";
import {FiEdit3} from "react-icons/fi";
import {useAppSelector} from "../../../../../app/hooks";
import {selectCart, selectCartType} from "../../../cartSlice";
import {useNavigate} from "react-router-dom";
import {selectUser} from "../../../../user/userSlice";
import {selectVendor} from "../../../../vendor/vendorSlice";

export function CartOrderDetails() {
    const cartSliceState = useAppSelector(selectCart);
    const vendor = useAppSelector(selectVendor);
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);
    const cartType = useAppSelector(selectCartType);

    return (
        <div className="flex indent place-content-between py-5">
            <div>
                <h2 className="text-2xl fore-color">Order</h2>
                <p className="pb-4">
                    {cartType === CartType.Delivery && (cartSliceState.deliveryMinOrder ?? 0) > 0 &&
                        <span className={'text-red-600'}>
                            Min order amount: &euro;{vendor.DeliveryMinCost}
                        </span>
                    }

                </p>

                {cartSliceState.type === CartType.Delivery && user.Address &&
                    <>
                        <p className="mb-2 font-bold">Delivery
                            <FiEdit3 className="inline-block ml-4 cursor-pointer"
                                     onClick={() => {navigate('/delivery/type')}}
                            />
                        </p>
                        <p className="text-gray-500">{user.Address.RoadName} {user.Address.Number}, {user.Address.District}</p>
                        <p className="primary-color mt-2 cursor-pointer font-light" onClick={() => {navigate('/address/list')}}>
                            Change Address
                        </p>
                    </>
                }
                {cartSliceState.type === CartType.TakeAway &&
                    <p className="mb-2 font-bold">Take Away
                        <FiEdit3 className="inline-block ml-4 cursor-pointer"
                                 onClick={() => {navigate('/delivery/type')}}
                        />
                    </p>
                }

            </div>
        </div>
    )
}
