import styled from "styled-components";

const Container = styled.div`
  margin-top: 5px;
  min-height: 110px;
  padding: 10px 0 10px 20px;
  display: flex;
  background-color: var(--theme-modded-card-bg);
  border-radius: 5px;
  box-shadow: var(--theme-shadow);
  margin-left: 10px;
  cursor: pointer;
  transition: all 100ms;

  &:hover {
    box-shadow: 0 4px 12px rgba(198, 201, 205, 0.55);;
  }

  &.inOrder {
    border-left: solid 10px var(--theme-primary-color);
    padding-left: 10px;
    border-radius: 0 5px 5px 0;
    margin-left: 0;
  }
`;

const Title = styled.div`
  font-size: 14pt;
  font-weight: 500;
  color: var(--theme-modded-fore-color);
`
const Description = styled.div`
  font-size: 11pt;
  line-height: 16px;
  color: var(--theme-grey-color);
  font-weight: 400;
  margin-top: 0.5rem;
`

const Details = styled.div`
  flex-grow: 1;
  padding-right: 5px;
`;

const Quantity = styled.div`
  display: inline-flex;
  background-color: var(--theme-primary-color);
  border-radius: 50%;
  width: 18px;
  aspect-ratio: 1;
  color: white;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

const Image = styled.div`
  min-width: 116px;
  max-width: 116px;
  height: 95px;
  background: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    margin-right: 2px;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;

const Price = styled.div`
  display: inline-block;
  color: var(--theme-primary-color);
  font-size: 16px;
  font-weight: 500;
  margin-right: 0.75rem;
`;

const Tag = styled.div`
  display: inline-flex;
  background-color: var(--theme-primary-color);
  color: white;
  padding: 3px 5px;
  border-radius: 6px;
  margin-right: 10px;
  font-size: var(--theme-fz-text);
`;

export {
    Container,Title,Description,Tag,Image,Price,Details,Quantity
}
