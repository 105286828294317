import {useAppSelector} from "../../../../app/hooks";
import {selectCart} from "../../cartSlice";
import {Cart} from "../../Cart";
import * as styled from "./Checkout.styled"
import {CartType} from "../../cartInterfaces";
import {selectUser} from "../../../user/userSlice";

export function CheckoutDetails() {
    const cart = useAppSelector(selectCart);
    const user = useAppSelector(selectUser);
    let cartO = new Cart(cart)

    return (
        <styled.CheckoutDetails>
            <div className={'mt-4'}>
                {cart.type === CartType.Delivery && user.Address &&
                    <>
                        <p className="mb-2 font-bold">Delivery</p>
                        <p className="text-gray-500">{user.Address.RoadName} {user.Address.Number}, {user.Address.District} </p>
                    </>
                }
                {cart.type === CartType.TakeAway &&
                    <>
                        <p className="mb-2 font-bold">Take Away</p>
                    </>
                }
            </div>
            <div className={'mt-4 lg:text-center'}>
                <p className="mb-2 font-bold">Amount</p>
                &euro;{cartO.displayFormattedPrice()}
            </div>
        </styled.CheckoutDetails>
    )
}
