import styled from "styled-components";

const Category = styled.div`
  padding: 15px;
  margin-top: 25px;
  margin-bottom: 5px;
  background-color: rgba(0,0,0,0.1);
  color: var(--theme-modded-fore-color);
  cursor: pointer;
  span{
    float: right;
  }
`;

const Subcategory = styled.div`
  color: var(--theme-primary-color);
  padding: 15px 20px;
  border-left: solid 2px var(--theme-primary-color);
  margin-left: 10px;
  cursor: pointer;
`;

export {Category,Subcategory}
