import * as styled from "./DeliveryTypeScreen.styled";
import * as StyledTheme from "../../../theme/styled-components/Theme.styled";
import {Footer} from "../../../theme/copmponents/Footer";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import React, {useEffect} from "react";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronRight} from "react-icons/bs";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {CartType} from "../../cartInterfaces";
import {setCartType, setDeliveryCost, setDeliveryMinOrder} from "../../cartSlice";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../intl/translator";
import {selectVendor} from "../../../vendor/vendorSlice";
import {isDeliveryAvailable} from "../../cartService";
import CONFIG from "../../../../app/config";
import {selectUser, setUserCompletedOrders} from "../../../user/userSlice";
import {UserStatus} from "../../../user/userInterfaces";
export function DeliveryTypeScreen() {
    const strings = {
        's_delivery': Translate('delivery'),
        's_take_away': Translate('take_away'),
        's_title': Translate('order_type_screen_title')
    }
    const divStyle = "";
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const vendor = useAppSelector(selectVendor);
    const user = useAppSelector(selectUser);
    useEffect(() => {
        window.history.pushState({}, 'item')
        const handleBack = (e: PopStateEvent) => {
            e.stopPropagation();
        }
        window.addEventListener('popstate', handleBack);
        return(() => {
            window.removeEventListener('popstate', handleBack);
        })

    },[])

    const submitCartType = (type: CartType) => {
        dispatch(setCartType(type));
        if(type === CartType.Delivery){
            dispatch(setDeliveryMinOrder(parseFloat(vendor.DeliveryMinCost ?? '0')));
            if(user && user.RegisteredUserID !== CONFIG.GUEST_USER_ID && user.Verified === "1"){
                navigate('/address/list');
            }else{
                navigate('/login')
            }
           
        }else{
            dispatch(setDeliveryMinOrder(0));
            dispatch(setDeliveryCost(0));
            navigate('/menu');
        }
    }
  
    const isConditionMet = vendor.OperationID !== "3" && vendor.IsOpen === "0";
    const cardPanelStyle: React.CSSProperties = {
      pointerEvents: isConditionMet ? 'none' : 'auto',
      // Add more styles as needed
    };
    

    const deliveryTextWithLineBreaks = vendor.DeliveryText?  vendor.DeliveryText.replace(/\n/g, '<br>') : " ";
    const deliveryClosed = vendor.CloseDeliveryMsg?  vendor.CloseDeliveryMsg.replace(/\n/g, '<br>') : " ";
    return (
        
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage/>
            <Footer negative="-60vh">
                <>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card}/>
                    </div>

                    <styled.DeliveryTypeScreen className="pt-36 px-8 pb-12 md:px-6">
                    {(vendor.OperationID != "3" && vendor.IsOpen == "0") &&
                    <h1 style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: deliveryClosed }}> 
                    
                    </h1>
                   
                    }
                  
                    <h1 dangerouslySetInnerHTML={{ __html: deliveryTextWithLineBreaks}}>
                    
                   
                        </h1>
                        <div className="options" style={{
                    pointerEvents:(vendor.OperationID != "3" && !vendor.IsOpen) ? 'none' : 'auto',
                    opacity: (vendor.OperationID != "3" && !vendor.IsOpen)  ? 0.9 : 1,
                    userSelect: (vendor.OperationID != "3" && !vendor.IsOpen)  ? 'none' : 'auto' // Prevents text selection when disabled
                }}>
                           
                            {isDeliveryAvailable(vendor) &&
                               
                               <div className='card-panel' onClick={() => submitCartType(CartType.Delivery)} style={cardPanelStyle}>
                                    <div className="image" >
                                        <img src="/images/delivery_options/delivery.png" alt="delivery"/>
                                    </div>
                                    <div className="title">
                                        <h3>{strings.s_delivery}</h3>
                                        <ActionButton ><BsChevronRight /></ActionButton>
                                    </div>
                                </div>
                            }
                            
                            <div style={cardPanelStyle} className='card-panel' onClick={() => submitCartType(CartType.TakeAway)}>
                                <div className="image">
                                    <img src="/images/delivery_options/take_away.png" alt="take_away"/>
                                </div>
                                <div className="title">
                                    <h3>{strings.s_take_away}</h3>
                                    <ActionButton ><BsChevronRight /></ActionButton>
                                </div>
                            </div>
                        </div>

                    </styled.DeliveryTypeScreen>
                </>
            </Footer>
        </div>
    )
}
