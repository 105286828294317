import {QuantitySwitch} from "../../../../core/components/QuantitySwitch/QuantitySwitch";
import {useState} from "react";
// import {TextP} from "../../../../../styled_components/General";
import {useAppSelector, useTimeout} from "../../../../../app/hooks";
import {MenuItemVariationOptionI} from "../../../menuInterfaces";
import {selectVendor} from "../../../../vendor/vendorSlice";
import {canShowVariationPrices} from "../../../menuService";
import * as styled from '../Product.styled';

interface ProductVariationInterface {
    roundBoxDesign?: boolean,
    option: MenuItemVariationOptionI,
    addOption: any,
    removeOption: any,
    qty: number
}

export function ProductVariation({roundBoxDesign, option, addOption, removeOption, qty}:ProductVariationInterface) {
    const vendor = useAppSelector(selectVendor);
    const showPrices = canShowVariationPrices({
        price: option.price, vendorVisibility: vendor.VisibilityOption
    })
    const getActiveSwitches = (): Array<string> => {
        let activeSwitchesStorage = localStorage.getItem('switches');
        let activeSwitches: Array<string> = [];
        if(activeSwitchesStorage) {
            activeSwitches = JSON.parse(activeSwitchesStorage)
        }
        return activeSwitches;
    }

    const [switchVisible, setSwitchVisible] = useState(getActiveSwitches().includes(option.OptionID));
    const [delay, setDelay] = useState(getActiveSwitches().includes(option.OptionID) ? 2000 : 0);

    const hideSwitch = () => {
        let activeSwitches = getActiveSwitches().filter(s => {
            return s !== option.OptionID;
        })
        localStorage.setItem('switches', JSON.stringify(activeSwitches));
        setSwitchVisible(false);
        setDelay(0);
    };

    useTimeout(hideSwitch, delay);

    const revealSwitch = () => {
        localStorage.setItem('switches', JSON.stringify([...getActiveSwitches(), option.OptionID]));
        setSwitchVisible(true);
        setDelay(delay === 2000 ? 2001 : 2000);
    }

    const initQty = () => {
        if(!switchVisible) {
            revealSwitch();
            if(qty === 0){
                addOption(option);
            }
        }
    }

    const addQty = (amt: number) => {
        revealSwitch();
        addOption(option);
    }

    const minusQty = (amt: number) => {
        revealSwitch();
        removeOption(option);
    }

    return (
        <div className="flex justify-between items-center mb-4 cursor-pointer" onClick={initQty}>
            <div className="flex items-center" style={{height: '30px'}}>
                {showPrices &&
                    <styled.ProductVariationQuantity populated={qty > 0} round={roundBoxDesign}>
                        {qty > 0 && qty }
                    </styled.ProductVariationQuantity>
                }
                <p className="fore-color">
                    {option.Name}
                </p>
            </div>
            {switchVisible && showPrices &&
                <QuantitySwitch add={addQty} deduct={minusQty} quantity={qty}/>
            }
            {!switchVisible && showPrices &&
                <styled.ProductVariationPrice active={qty > 0}>
                    &euro;{Number(option.price).toFixed(2)}
                </styled.ProductVariationPrice>
            }

        </div>
    );
}
