import {useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../../app/hooks";
import {selectUser} from "../../userSlice";
import {useEffect, useState} from "react";
import {UserAddressI} from "../../userInterfaces";
import {EditAddress} from "./EditAddress";


export function EditAddressResolver() {
    let {id} = useParams();
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);
    const [address, setAddress] = useState<UserAddressI>();

    useEffect(() => {
        if(id){
            let found = false;
            user.Addresses.forEach(a => {
                if(id === a.LocationID){
                    found = true;
                    setAddress(a);
                }
            })
            if(!found){
                navigate('/address/list')
            }
        }else{
            navigate('/address/list')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])


    return (
        <>
            {address &&
                <EditAddress address={address} />
            }
        </>
    )
}
