import * as styled from './UserAddresses.styled';
import React, {useEffect, useState} from "react";
import {AddressCard} from "./AddressCard";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectUser, selectUserAddresses, setUserAddress, setUserAddresses} from "../../userSlice";
import {selectVendor} from "../../../vendor/vendorSlice";
import {listUserAddresses} from "../../userRepository";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {AddAddressBox} from "./AddAddressBox";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronLeft} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";

export function UserAddresses() {
    const [loading, setLoading] = useState(true);
    const user = useAppSelector(selectUser);
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
    const addresses = useAppSelector(selectUserAddresses);
    const navigate = useNavigate();

    useEffect(() => {
        loadAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadAddresses = () => {
        listUserAddresses(user.RegisteredUserID, vendor.OutletID)
            .then(res => {
                dispatch(setUserAddresses(res));
                dispatch(setUserAddress());
                setLoading(false);
            })
    }

    const back = () => {navigate('/delivery/type')}

    return (
        <styled.UserAddresses>
            <div className="header-bar">
                <div className="actions">
                    <div className="flex items-center cursor-pointer" onClick={back}>
                        <ActionButton>
                            <BsChevronLeft size={24}/>
                        </ActionButton>
                        <div className="ml-4 back-btn-label">PREVIOUS STEP</div>
                    </div>
                </div>
                <div className="logo">
                    <VendorLogo placement={VendorLogoPosition.Card}/>
                    {/*<img src="/images/branding/logo_alt.svg" alt="nina logo"/>*/}
                </div>
            </div>
            <h1>Your Addresses</h1>
            {!loading &&
                <div className="results">
                    {addresses.map(address => {
                        return <AddressCard key={address.LocationID} address={address} />
                    })}
                    <AddAddressBox />
                </div>
            }
            {loading &&
                <AiOutlineLoading3Quarters size={50}
                                           className="animate-spin m-auto"
                                           color="var(--theme-sky-blue)"/>
            }

        </styled.UserAddresses>
    )
}
