import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import {useEffect, useRef, useState} from "react";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useTimeout(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (!delay && delay !== 0) {
            return
        }
        const id = setTimeout(() => savedCallback.current(), delay)
        return () => clearTimeout(id)
    }, [delay])
}


export function useScreenOrientation() {
    const getOrientation = () => {
        if(window.screen.availHeight > window.screen.availWidth){
            return 'portrait-primary';
        }else{
            return 'landscape-primary';
        }
    };
    const [orientation, setOrientation] = useState(getOrientation);
    const onResize = () => setOrientation(getOrientation());
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', getOrientation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return orientation;
}
