import styled from "styled-components";

const Subcategory = styled.div`
  margin-right: var(--theme-walls-indentation);
  margin-left: calc(var(--theme-walls-indentation) / 2);
  border-left: solid 2px var(--theme-primary-color);
`;

const Title = styled.h3`
  color: var(--theme-primary-color);
  margin-top: 1rem;
  padding-left: var(--theme-walls-indentation);
  font-size: 22px;
`
export {Subcategory, Title}
