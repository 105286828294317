import {MenuCategoryI} from "../../../menuInterfaces";
import * as Scroll from 'react-scroll';
import * as styled from './Category.styled';

interface CategoryContainerI{
    category: MenuCategoryI,
    isActive: boolean
}

export function Category({category, isActive}: CategoryContainerI) {
    const scroller = Scroll.scroller;

    const showCategory = () => {
        scroller.scrollTo('category-'+ category.Id, {
            smooth: false,
            offset: -130,
            ignoreCancelEvents: true,
        });
    }

    return (
        <Scroll.Element name={'sticky-category-'+ category.Id}
                 id={'sticky-category-'+ category.Id}
        >
            <styled.Category
                onClick={showCategory}
                isActive={isActive}
                isDisabled={category.CurrentlyAvailable === "0"}
            >
                {category.Name}
            </styled.Category>
        </Scroll.Element>

    )
}
