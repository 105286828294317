import {MenuCategoryI} from "../../../menuInterfaces";
import {selectMenu, selectProductView} from "../../../menuSlice";
import {useAppSelector} from "../../../../../app/hooks";
import {useEffect, useState} from "react";
import {v4 as uuid} from "uuid";
import {Subcategory} from "./Subcategory";
import {generateSubcategoryId} from "../../../menuService";
import {scroller} from "react-scroll";

interface StickyHeaderSubcategoriesI {
    stickyShown: boolean,
    activeMainCategory: string
}

export function Subcategories({stickyShown, activeMainCategory}: StickyHeaderSubcategoriesI) {

    const menu: Array<MenuCategoryI> = useAppSelector(selectMenu);
    const productView = useAppSelector(selectProductView);
    const [activeCategory, setActiveCategory] = useState("-");
    const bottomHeight = window.innerHeight - 265;
    const observerOptions = {
        rootMargin: '-210px 0% -'+bottomHeight+'px 0%',
        threshold: [0,0.25,0.50,0.75,1]
    }

    const observer = new IntersectionObserver((entries, observer) => {
        let ratio = 0;
        let activeEntry: string = "-";
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                if(entry.intersectionRatio > ratio){
                    ratio = entry.intersectionRatio;
                    activeEntry = entry.target.id
                }
            }
        })
        if(activeEntry !== "-"){
            setActiveCategory(activeEntry);
            scroller.scrollTo('sticky-'+ activeEntry, {
                smooth: true,
                containerId: 'sticky-menu-subcategories',
                horizontal: true,
                offset: -50,
                duration: 200,
                ignoreCancelEvents: true,
            });
        }
    }, observerOptions)

    useEffect(() => {
        const container = document.getElementById('sticky-menu-subcategories')
        if(container && stickyShown) {
            setTimeout(() => {
                container.scrollLeft = 0;
            },300)

        }
    }, [stickyShown])

    useEffect(() => {
        menu.forEach((category) => {

            if(category.Subcategories.length > 0) {
                category.Subcategories.forEach(subcategory => {
                    let el =  document.getElementById(generateSubcategoryId(category.Id, subcategory));
                    if(el) {
                        observer.observe(el);
                    }
                })
            }

        })
        return () => {
            menu.forEach((category) => {
                if(category.Subcategories.length > 0) {
                    category.Subcategories.forEach(subcategory => {
                        let el =  document.getElementById(generateSubcategoryId(category.Id, subcategory));
                        if(el) {
                            observer.observe(el);
                        }
                    })
                }

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stickyShown, productView, activeMainCategory]);

    return (
        <div id="sticky-menu-subcategories" className="py-2 px-5 overflow-scroll flex no-scrollbar">
            {menu.map(category => {
                if(category.Subcategories.length > 0 &&
                    stickyShown &&
                    activeMainCategory === 'category-' + category.Id
                ){

                    return category.Subcategories.map(subcategory => {
                        if (subcategory.Name !== 'None') {
                            return <Subcategory
                                subcategory={subcategory}
                                category={category}
                                isActive={activeCategory.includes('subcategory-'+subcategory.Id)}
                                key={uuid()}
                            />
                        }else{
                            return <div key={uuid()}></div>
                        }
                    })
                }else{
                    return <div key={uuid()}></div>
                }

            })}
        </div>
    );
}
