import React, {useEffect} from 'react';
import './../styles/reset.css';
import './../styles/theme.css';
import {useAppSelector} from "../../../app/hooks";
import {selectMode, selectVendorColor} from "./../themeSlice";

type Props = {
    children: JSX.Element
}
export function Theme({children} :Props) {
    const mode = useAppSelector(selectMode);
    const vendorColor = useAppSelector(selectVendorColor);

    useEffect(() => {
        if(mode === 'dark'){
            setDarkModeStyles();
        }else{
            setLightModeStyles();
        }
    }, [mode])

    useEffect(() => {
        setVendorStyles(vendorColor)
    }, [vendorColor])

    return (
        <div className={`Nina-App mode-${mode}`}>{children}</div>
    );


}
const setDarkModeStyles = () => {
    document.documentElement.style.setProperty('--theme-modded-bg-color', "#333333");
    document.documentElement.style.setProperty('--theme-modded-fore-color', "#fff");
    document.documentElement.style.setProperty('--theme-modded-sticky-bg', "none");
    document.documentElement.style.setProperty('--theme-modded-card-bg', "none");
    document.documentElement.style.setProperty('--theme-shadow', "0 0px 1px #f1f4f9");
    document.documentElement.style.setProperty('--rsbs-bg', "#333333");
}

const setLightModeStyles = () => {
    document.documentElement.style.setProperty('--theme-modded-bg-color', "#fff");
    document.documentElement.style.setProperty('--theme-modded-fore-color', "#000");
    document.documentElement.style.setProperty('--theme-modded-sticky-bg', "rgba(255, 255, 255, 0.8)");
    document.documentElement.style.setProperty('--theme-modded-card-bg', "#fcfeff");
    document.documentElement.style.setProperty('--theme-shadow', "0 4px 12px #f1f4f9");
    document.documentElement.style.setProperty('--rsbs-bg', "#fff");
}

const setVendorStyles = (vendorColor: string) => {
    document.documentElement.style.setProperty('--theme-primary-color', '#'+vendorColor);
}
