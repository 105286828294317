import {CartItem} from "../../../CartItem";
import {MenuItemQuantity} from "../CartScreen.styled";
import {MenuItem} from "../../../../menu/MenuItem";
import {setItemSelected, setProductView} from "../../../../menu/menuSlice";
import {useAppDispatch} from "../../../../../app/hooks";
import {LightboxOptions, setOpenLightbox} from "../../../../core/panelsSlice";
import {Translate} from "../../../../intl/translator";

interface CartScreenItemI {
    item: CartItem
}

interface CartVariationDisplayI{
    id: string,
    name: string,
    qty: number,
}

export function CartScreenItem({item}: CartScreenItemI) {
    const dispatch = useAppDispatch();
    const strings = {
        's_notes': Translate('notes')
    }

    const prepareVariations = (): Array<CartVariationDisplayI> => {
        let result: Array<CartVariationDisplayI> = [];
        item.Variations.forEach(variationGroup => {
            variationGroup.items.forEach(variation => {
                const i = result.findIndex(dv => dv.id === variation.OptionID);
                if(i >= 0){
                    result[i].qty++;
                }else{
                    result.push({
                        id: variation.OptionID,
                        name: variation.Name,
                        qty: 1
                    })
                }
            })
        })
        return result;
    }

    const openItem = () => {
        console.log('test')
        dispatch(setItemSelected(new MenuItem(item.Product)));
        dispatch(setProductView({
            mode: "cart",
            open: true,
            cartItem: item
        }))
        dispatch(setOpenLightbox(LightboxOptions.PRODUCT));
    }

    return (
        <div className="mb-3 cursor-pointer" onClick={openItem}>
            <div className="flex place-content-between items-center content-center">
                <div className="flex items-center">
                    <MenuItemQuantity>{item.Qty}</MenuItemQuantity>
                    <span className="inline-block pr-2 pl-1 fore-color">
                      {item.Product.ItemName}
                    </span>

                </div>
                <div>
                    <span className="" style={{
                        color: "var(--theme-primary-color)",
                        paddingLeft: "10px"
                    }}>&euro;{item.displayFormattedPrice()}</span>
                </div>
            </div>
            <ul className="list-disc ml-8">
                {
                    prepareVariations().map(variation => {
                        return <CartScreenItemVariation key={variation.id} {...variation} />
                    })
                }
            </ul>
            {item.Notes &&
                <div className="indent mt-1 text-lg">
                    <p className="" style={{color: "var(--theme-primary-color)"}}>{strings.s_notes}:</p>
                    <p>{item.Notes}</p>
                </div>
            }



        </div>
    )
}

function CartScreenItemVariation(variation: CartVariationDisplayI) {
    return (
        <li style={{color: "grey"}} key={variation.id} className="mt-1">
            <span className="">
                {variation.name}
                {variation.qty > 1 &&
                    <span> (x{variation.qty})</span>
                }
            </span>
        </li>
    )
}
