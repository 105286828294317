import {UserAddressI, UserI, UserStatus} from "./userInterfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

const initialState: UserI = {
    RegisteredUserID: "113",
    CompletedOrdersCount: 0,
    Verified: UserStatus.UnVerified,
    Addresses: []
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        initUser: (state, action: PayloadAction<UserI>) => {
            state.RegisteredUserID = action.payload.RegisteredUserID;
            state.PhoneNum = action.payload.PhoneNum;
            state.PhoneCode = action.payload.PhoneCode;
            state.Verified = action.payload.Verified;
        },
        setUserFormData: (state, action: PayloadAction<UserI>) => {
            state.PhoneNum = action.payload.PhoneNum;
            state.PhoneCode = action.payload.PhoneCode;
            state.Email = action.payload.Email;
            state.Name = action.payload.Name;
            state.Surname = action.payload.Surname;
        },
        setUserVerificationStatus: (state, action: PayloadAction<string>) => {
            state.Verified = action.payload;
        },
        setUserCompletedOrders: (state, action: PayloadAction<number>) => {
            state.CompletedOrdersCount = action.payload;
        },
        setUserAddresses: (state, action: PayloadAction<Array<UserAddressI>>) => {
            state.Addresses = action.payload;
        },
        setUserAddress: (state, action: PayloadAction<UserAddressI | undefined>) => {
            state.Address = action.payload;
        },
        setUserFirebaseToken: (state, action: PayloadAction<string>) => {
            state.FirebaseToken = action.payload;
        },
        logoutUser: (state) => {
            state.RegisteredUserID = "113";
            state.Verified = UserStatus.UnVerified;
            state.CompletedOrdersCount = 0;
            state.PhoneCode = undefined;
            state.PhoneNum = undefined;
            state.Email = undefined;
            state.Name = undefined;
            state.Surname = undefined;
            state.Addresses = [];
            state.Address = undefined;
        }
    }
})

export default userSlice.reducer;
export const {initUser, setUserFormData, setUserVerificationStatus,setUserCompletedOrders, setUserFirebaseToken, setUserAddresses, setUserAddress, logoutUser} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export const selectUserAddresses = (state: RootState) => state.user.Addresses;
export const selectUserAddress = (state: RootState) => state.user.Address;
