import * as styled from './QuantitySwitch.styled';
import {IoIosAdd} from "react-icons/io";
import {HiMinusSm} from "react-icons/hi";

interface QuantitySwitchI {
    add: any,
    deduct: any,
    quantity: number,
    maxQ?: number,
    minQ?: number
}

export function QuantitySwitch({add, deduct, quantity, maxQ, minQ}: QuantitySwitchI) {

    const addQty = () => {
        if(maxQ){
            if(quantity < maxQ){
                add(1);
            }
        }else{
            add(1)
        }
    }

    return (
        <styled.QuantitySwitchContainer>
            <styled.QuantitySwitchButton outline onClick={() => deduct(1)}>
                <HiMinusSm/>
            </styled.QuantitySwitchButton>
            <styled.QuantitySwitchNumber>
                {quantity < 10 && '0'}
                {quantity}
            </styled.QuantitySwitchNumber>
            <styled.QuantitySwitchButton onClick={addQty} disabled={quantity >= (maxQ ?? 10)}>
                <IoIosAdd size={20}/>
            </styled.QuantitySwitchButton>
        </styled.QuantitySwitchContainer>
       
    )
}
