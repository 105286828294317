import styled from "styled-components";

const CartSidebar = styled.div`
  box-shadow: var(--theme-shadow);
  margin-top: 30px;
  @media(max-width: 960px) {
    display: none;
  }
`

const MenuItemQuantity = styled.div`
  display: inline-flex;
  background-color: var(--theme-primary-color);
  border-radius: 50%;
  width: 18px;
  min-width: 18px;
  aspect-ratio: 1;
  color: white;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;



export {
    CartSidebar,
    MenuItemQuantity
}
