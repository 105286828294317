import {MenuCategoryI, MenuSubcategoryI} from "../../../menuInterfaces";
import {generateSubcategoryId} from "../../../menuService";
import * as Scroll from "react-scroll";
import {Element} from "react-scroll";
import * as styled from './Subcategory.styled';

interface StickyMenuSubcategoryContainerI{
    subcategory: MenuSubcategoryI,
    isActive: boolean,
    category: MenuCategoryI
}

export function Subcategory({subcategory, isActive, category}: StickyMenuSubcategoryContainerI) {

    const scroller = Scroll.scroller;
    const identifier = generateSubcategoryId(category.Id, subcategory);

    const showCategory = () => {
        scroller.scrollTo((identifier), {
            smooth: false,
            offset: -210,
            ignoreCancelEvents: true,
        });
    }

    return (
        <Element name={'sticky-' + identifier}>
            <styled.Subcategory
                onClick={showCategory}
                id={'sticky-' + identifier}
                isActive={isActive}>
                {subcategory.Name}
            </styled.Subcategory>
        </Element>
    )
}
