import {Gateway} from "../Gateway";
import axios from "axios";
import CONFIG from "../../../../../../app/config";

/**
 * VivaHandler
 *
 * @class VivaHandler
 * @extends {Gateway}
 */
export class VivaHandler extends Gateway{


    submit = ()  => {
        this.getPaymentRequestID().then(id => {
            this.getRedirectUrl(id).then(res => {
                this.props.navigate('/order/failed');
                window.location.href = res.data.RedirectLink;
            })
        })
    }

    getRedirectUrl = (requestId: string) => {
        return axios.post(`${CONFIG.API_URL}/payments/viva/request`, {
            orderId: requestId,
            orderTotal: this.cart.calcCartPrice().toString(),
            accountId: this.props.paymentType.AccountID,
            userId: this.props.user.RegisteredUserID
        })
    }


}
