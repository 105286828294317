import {CartI, CartItemI, CartType} from "./cartInterfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

const initialState: CartI = {
    items: [],
    notes: "",
    type: CartType.TakeAway,
    deliveryMinOrder: 0
}

export const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        addItemToCart: (state, action: PayloadAction<CartItemI>) => {
            state.items = [...state.items, action.payload]
        },
        removeItemFromCart: (state, action: PayloadAction<CartItemI>) => {
            let items = [...state.items];
            const i = items.findIndex(item => {
                return item.Id === action.payload.Id;
            })
            if(i >= 0) {
                items.splice(i, 1);
            }
            state.items = [...items];
        },
        updateItemFromCart: (state, action: PayloadAction<CartItemI>) => {
            let items = [...state.items];
            const i = items.findIndex(item => {
                return item.Id === action.payload.Id;
            })
            if(i >= 0) {
                items[i] = action.payload;
            }
            state.items = [...items];
        },
        setCart:(state, action: PayloadAction<CartI>) => {
            state.items = [...action.payload.items];
        },
        setCartNotes:(state, action: PayloadAction<string>) => {
            state.notes = action.payload;
        },
        setCartType:(state, action: PayloadAction<CartType>) => {
            state.type = action.payload
        },
        setDeliveryCost:(state, action: PayloadAction<number>) => {
            state.deliveryCharge = action.payload
        },
        setDeliveryMinOrder:(state, action: PayloadAction<number>) => {
            state.deliveryMinOrder = action.payload
        },
        clearCart: state => {
            state.items = [];
            state.notes = "";
        }
    }
})

export default cartSlice.reducer;
export const {addItemToCart, removeItemFromCart,updateItemFromCart, clearCart, setCart, setCartNotes, setCartType, setDeliveryCost, setDeliveryMinOrder} = cartSlice.actions;
export const selectCart = (state: RootState) => state.cart
export const selectCartComments = (state: RootState) => state.cart.notes;
export const selectCartType = (state: RootState) => state.cart.type;
export const selectMinOrder = (state: RootState) => state.cart.deliveryMinOrder;
