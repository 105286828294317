import {MenuItem} from "../MenuItem/MenuItem";
import {v4 as uuid} from "uuid";
import {generateSubcategoryId} from "../../../menuService";
import {MenuSubcategoryI} from "../../../menuInterfaces";
import * as styled from './MenuSubcategory.styled';

export function MenuSubcategory(subcategory: MenuSubcategoryI) {
    const identifier = generateSubcategoryId(subcategory.categoryId ?? "", subcategory);
    return (
        <>
            {subcategory.Name !== 'None' &&
                <styled.Subcategory id={identifier} className="mb-6 subcategory-sticky-identifier" key={uuid()}>
                    <styled.Title key={uuid()} >
                        {subcategory.Name}
                    </styled.Title>
                    {subcategory.Items.map(item => {
                        if(!item.filtered) {
                            return <MenuItem key={uuid()} inCart={0} item={item}/>
                        }
                        return null;
                    })}
                </styled.Subcategory>
            }
            {subcategory.Name === 'None' &&
                <styled.Subcategory id={identifier}
                                   className="mb-10 subcategory-sticky-identifier"
                                   key={uuid()}
                >
                    {subcategory.Items.map(item => {
                        if(!item.filtered) {
                            return <MenuItem key={uuid()} inCart={0} item={item}/>
                        }
                        return null;
                    })}
                </styled.Subcategory>
            }
        </>
    )
}
