import {
    MenuItemAllergenI,
    MenuItemGalleryI,
    MenuItemI,
    MenuItemVariationGroupI
} from "./menuInterfaces";

export interface MenuItem extends MenuItemI {}
export class MenuItem {

    Gallery: Array<MenuItemGalleryI> = [];
    Allergens: Array<MenuItemAllergenI> = [];
    Variations: Array<MenuItemVariationGroupI> = [];

    constructor(menuItem: MenuItemI | null) {
        Object.assign(this, menuItem)
    }


}
