// src/BlueButton.js
import React from 'react';

const BlueButton = ({ onClick, children }) => {
  const buttonStyle = {
    backgroundColor: '#000080',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
  };

  const hoverStyle = {
    backgroundColor: 'darkblue',
  };

  const activeStyle = {
    backgroundColor: 'navy',
  };

  const [isHovered, setIsHovered] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  const combinedStyle = {
    ...buttonStyle,
    ...(isHovered ? hoverStyle : {}),
    ...(isActive ? activeStyle : {}),
  };

  return (
    <button
      style={combinedStyle}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
    >
      {children}
    </button>
  );
};

export default BlueButton;
