import * as styled from './SideBar.styled'
import {useAppDispatch} from "../../../../app/hooks";
import {setOpenSidebar, SideBarOptions} from "../../panelsSlice";

type Props = {
    children: JSX.Element,
    open: boolean
}

export function SideBar({children, open} :Props) {
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(setOpenSidebar(SideBarOptions.CLOSED))
    }

    return (
        <styled.SideBar open={open}>
            <div className="backdrop" onClick={close}></div>
            <div className="inner">
                {children}
            </div>
        </styled.SideBar>
    )
}
