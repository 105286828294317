import {MenuItem} from "../menu/MenuItem";
import {v4 as uuid} from "uuid";
import {CartItemGroupValidationError, CartItemI, cartItemVariationGroupI} from "./cartInterfaces";
import {MenuItemVariationGroupI, MenuItemVariationOptionI} from "../menu/menuInterfaces";


export class CartItem {
    Id: string;
    Product: MenuItem;
    Qty: number;
    Variations: Array<cartItemVariationGroupI>;
    Notes: string;
    GroupValidationErrors: Array<CartItemGroupValidationError>

    constructor(item: CartItemI) {
        this.Id = item.Id ?? uuid();
        this.Qty = item.Qty ?? 1;
        this.Product = item.Product;
        this.Notes = item.Notes ?? ""
        this.Variations = [];
        this.GroupValidationErrors = item.GroupValidationErrors ?? [];
        if(item.Variations?.length){
            let variants: Array<cartItemVariationGroupI> = [];
            item.Variations.forEach(v => {
                variants.push({
                    group: {...v.group},
                    items: [...v.items]
                })
            })
            this.Variations = variants;
        }else{
            this.Variations = [];
        }
    }

    calcPrice(): number {
        let price = 0;
        if (this.Product.Price !== "-") {
            this.Variations.forEach(group => {
                group.items.forEach(option => {
                    price += this.priceToCents(option.price ?? "0");
                })
            })
            price += this.priceToCents(this.Product.Price);
            price = price * this.Qty;
        }
        if(this.isComplimentary()){return  0}
        return price;
    }

    priceToCents(price: string): number {
        return parseFloat(price) * 100
    }

    displayFormattedPrice(): string {
        return (this.calcPrice() / 100).toFixed(2).toString();
    }

    addItemQty(): CartItem {
        this.Qty += 1;
        return this.UpdatedCartItemInstance();
    }

    deductItemQty(): CartItem {
        this.Qty -= 1;
        return this.UpdatedCartItemInstance();
    }

    findVariationGroupCartOptions(group: MenuItemVariationGroupI): Array<MenuItemVariationOptionI> {
        const i = this.Variations.findIndex((v) => {
            return v.group.ItemVariationID === group.ItemVariationID
        })
        if (i >= 0) {
            return this.Variations[i].items;
        }
        return [];
    }

    toggleVariationSingleToGroup(
        {group, option}: {
            group: MenuItemVariationGroupI,
            option: MenuItemVariationOptionI
        }
    ): CartItem {
        const groupIndex = this.Variations.findIndex((v) => {
            return v.group.ItemVariationID === group.ItemVariationID
        })
        if (groupIndex >= 0) {
            const isChecked = this.Variations[groupIndex].items.some(item => {
                return item.OptionID === option.OptionID
            })
            if (isChecked) {
                this.Variations[groupIndex].items = this.Variations[groupIndex].items.filter(item => {
                    return item.OptionID !== option.OptionID;
                })
            } else {
                this.Variations[groupIndex].items.push(option);
            }
        } else {
            this.Variations.push({group, items: [option]});
        }
        return this.UpdatedCartItemInstance();
    }

    addVariationQtyToGroup(
        {group, option}: {
            group: MenuItemVariationGroupI,
            option: MenuItemVariationOptionI
        }
    ): CartItem {
        const groupIndex = this.Variations.findIndex((v) => {
            return v.group.ItemVariationID === group.ItemVariationID
        })
        if (groupIndex >= 0) {
            this.Variations[groupIndex].items.push(option);
        } else {
            this.Variations.push({group, items: [option]});
        }
        return this.UpdatedCartItemInstance();
    }

    deductVariationQtyToGroup(
        {group, option}: {
            group: MenuItemVariationGroupI,
            option: MenuItemVariationOptionI
        }
    ): CartItem {
        const groupIndex = this.Variations.findIndex((v) => {
            return v.group.ItemVariationID === group.ItemVariationID
        })
        if (groupIndex >= 0) {
            const optionIndex = this.Variations[groupIndex].items.findIndex(o => o.OptionID === option.OptionID);
            if (optionIndex >= 0) {
                this.Variations[groupIndex].items.splice(optionIndex, 1);
            }
        }
        return this.UpdatedCartItemInstance();
    }

    selectRadioVariationOfGroup(
        {group, option}: {
            group: MenuItemVariationGroupI,
            option: MenuItemVariationOptionI
        }
    ): CartItem {
        const groupIndex = this.Variations.findIndex((v) => {
            return v.group.ItemVariationID === group.ItemVariationID
        })
        if (groupIndex >= 0) {
            this.Variations[groupIndex].items = [option];
        } else {
            this.Variations.push({group, items: [option]});
        }
        return this.UpdatedCartItemInstance();
    }

    setNotes(note: string): CartItem {
        this.Notes = note;
        return this.UpdatedCartItemInstance();
    }

    isChanged(item: CartItem): boolean {
        return JSON.stringify(this) === JSON.stringify(item);
        // return false;
    }

    getMaxQty(): number {
        if(this.isComplimentary()){
            return 1;
        }
        return 10;
    }
    isComplimentary(): boolean {
        return this.Product.CategoryName === 'Complimentary';
    }

    validate(highlightErrors: boolean = false): CartItem {
        this.validateRequiredGroups();
        if(highlightErrors){
            this.validationHighlightErrors();
        }
        return this.UpdatedCartItemInstance();
    }

    validationHighlightErrors() {
        if (this.GroupValidationErrors.length > 0) {
            let groupId = "group-container-" + this.GroupValidationErrors[0].group.ItemVariationID
            let groupEl = window.document.getElementById(groupId);
            if(groupEl){
                let topPosition = groupEl.getBoundingClientRect().top
                if(topPosition > 600){
                    // @ts-ignore
                    groupEl.offsetParent.firstChild.scrollTo({top: topPosition - 385, behavior: 'smooth'});
                }else if(topPosition < 200){
                    // @ts-ignore
                    let currentScroll = groupEl.offsetParent.firstChild.scrollTop;
                    // @ts-ignore
                    groupEl.offsetParent.firstChild.scrollTo({
                        top: currentScroll - (385 - topPosition),
                        behavior: 'smooth'});
                }

            }
        }
    }

    validateRequiredGroups(): boolean {
        let errors = 0;
        this.Product.Variations.forEach( (group)=>  {
            let added: Array<cartItemVariationGroupI> = [];
            if(group.isRequire === "1"){
                added = this.Variations.filter(variation => {
                    return (variation.group.ItemVariationID === group.ItemVariationID
                        && variation.items.length > 0
                    )
                });
                this.GroupValidationErrors = this.GroupValidationErrors.filter(gError => group.ItemVariationID !== gError.group.ItemVariationID);
                if(added.length === 0){
                    this.GroupValidationErrors.push({
                        group,
                        msg: "Please select one option."
                    })
                }
            }
        })
        return (errors === 0);
    }

    // return always a new instance for immutability purposes
    UpdatedCartItemInstance(): CartItem {
        return new CartItem({
            Id: this.Id,
            Product: this.Product,
            Qty: this.Qty,
            Variations: this.Variations,
            Notes: this.Notes,
            GroupValidationErrors: this.GroupValidationErrors
        });
    }
}

