import {CartI, CartItemI} from "./cartInterfaces";
import {CartItem} from "./CartItem";
import {isEqual} from "lodash";

export class Cart{
    Items: Array<CartItemI>;
    DeliveryCharge: number;
    DeliveryMinCharge: number = 0;

    constructor(cart: CartI) {
        this.Items = cart.items;
        this.DeliveryCharge = cart.deliveryCharge ?? 0;
        this.DeliveryMinCharge = cart.deliveryMinOrder ?? 0;
    }

    itemsCount():number {
        let count = 0;
        this.Items.forEach(item => {
            count += item.Qty ?? 0;
        })
        return count;
    }

    calcCartPrice():number {
        let total = this.calcCartItemsTotal();
        total += this.DeliveryCharge * 100;
        total += this.calcDeliveryMinOrderFee() * 100;
        return total;
    }

    calcCartItemsTotal():number {
        let total = 0;
        this.Items.forEach(item => {
            if(item) {
                const itm = new CartItem(item);
                total += itm.calcPrice();
            }
        })
        return total;
    }

    calcDeliveryMinOrderFee():number {
        let fee = 0;
        if((this.DeliveryMinCharge ?? 0) > 0){
            fee = this.DeliveryMinCharge - (this.calcCartItemsTotal() / 100);
        }
        return fee > 0 ? fee : 0;
    }


    containsSameItem(item: CartItem): boolean {
        return this.findSameCartItems(item).length > 0;
    }

    findSameCartItems(item: CartItem): Array<CartItemI> {
        return this.Items.filter(itm => {
            let cartItem = new CartItem(itm);
            return (
                isEqual(cartItem.Product.MenuItemID, item.Product.MenuItemID) &&
                isEqual(cartItem.Variations, item.Variations) &&
                isEqual(cartItem.Product.CategoryName, item.Product.CategoryName) &&
                isEqual(cartItem.Notes, item.Notes)
            )
        });
    }

    findComplimentaryItems(): Array<CartItemI> {
        return this.Items.filter(itm => {
            let cartItem = new CartItem(itm);
            return cartItem.isComplimentary();
        });
    }

    filterComplimentaryItems(): Cart{
        this.Items = this.Items.filter(itm => {
            let cartItem = new CartItem(itm);
            return !cartItem.isComplimentary();
        })
        return this.updatedCartInstance();
    }


    displayFormattedPrice():string {
        return (this.calcCartPrice() / 100).toFixed(2).toString();
    }

    updatedCartInstance(): Cart {
        let cart: CartI = {items: this.Items};
        return new Cart(cart);
    }

}
