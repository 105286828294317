import {AppInputField} from "../../../theme/styled-components/Form.styled";
import React, {RefObject, useEffect} from "react";
import {BsSearch} from "react-icons/bs";
import {PostCodeResults} from "./PostCodeResults";
import {CgCloseO} from "react-icons/cg";
import {Translate} from "../../../intl/translator";

interface Props {
    setStep: any;
    setPostCode: any;
    setAddress: any;
    postCodeFieldRef: RefObject<HTMLInputElement>;
    postCode: string
}

export function AddAddressStep2({setStep, postCodeFieldRef, setPostCode, postCode, setAddress}: Props) {

    const strings = {
        's_post_code': Translate('post_code'),
        's_title': Translate('add_address_step_2_title'),
        's_desc1': Translate('add_address_step_2_desc_1'),
        's_desc2': Translate('add_address_step_2_desc_2'),
    }
    useEffect(() => {
        if (postCodeFieldRef.current) {
            postCodeFieldRef.current.focus();
            postCodeFieldRef.current.value = postCode;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearField = () => {
        if (postCodeFieldRef.current) {
            postCodeFieldRef.current.value = '';
            setPostCode("");
            postCodeFieldRef.current.focus();
        }
    }

    return (
        <div className='step-2'>
            <h1>{strings.s_title}.</h1>
            <p>{strings.s_desc1} <br/>{strings.s_desc2}</p>
            <span>{strings.s_post_code}</span>
            <div className={'input-wrapper mb-12'}>
                <AppInputField ref={postCodeFieldRef} onChange={(e) => setPostCode(e.target.value)}/>
                <BsSearch size={22} className={'search-icon'}/>
                {postCode.length > 0 &&
                    <CgCloseO size={22} className={'close-icon'} onClick={clearField}/>
                }
            </div>

            <PostCodeResults
                postCodeFieldRef={postCodeFieldRef}
                postCode={postCode}
                setAddress={setAddress}
                setStep={setStep}
            />

           {/* <FormButton className="mt-6" background="var(--theme-sky-blue)"
                        onClick={() => setStep(1)}>
                Continue
            </FormButton>*/}
        </div>
    )
}
